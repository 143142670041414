export const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'
export const RESPONSE_CHOICE_API_URL = '/v3/respondent/api/v1.0/responseChoice'
export const RESPONDENT_STATUS_API_URL =
  '/v3/respondent/api/v1.0/respondentStatus'
export const RESPONDENT_INFO_API_URL = '/v3/respondent/api/v1.0/respondentInfo'
export const RESPONDENT_QUESTIONNAIRE_API_URL =
  '/v3/respondent/api/v1.0/questionnaire'
export const ROUTE_TO_END_SURVEY_NUMBER = 100000
export const SETTING_VALUE_MULTIPLE_CHOICE = 'multiple_choice'
export const RESPONDENT_CHOICE_KEY = 'respondentChoices'
