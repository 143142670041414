import { useState } from 'react'
import useQueryParameters from './useQueryParams'

export interface MandatoryQueryParams {
  respondentId: string
  surveyId: string
  panelSupplier: string
  preview: boolean
}

export const useMandatoryQueryParams: () => MandatoryQueryParams | null =
  () => {
    const queryParams = useQueryParameters()
    const [state] = useState<MandatoryQueryParams | null>(() => {
      const respondentId: string | null = queryParams.get('rid')
      const surveyId: string | null = queryParams.get('sid')
      const panelSupplier: string | null = queryParams.get('ps')
      const isPreviewString: string | null = queryParams.get('preview')

      if (!respondentId || !surveyId || !panelSupplier) {
        return null
      }

      return {
        respondentId,
        surveyId,
        panelSupplier,
        preview: isPreviewString === 'true'
      }
    })

    return state
  }
