import { RespondentProgress } from 'model/respondentProgress'
import { useCallback, useState } from 'react'

const RESPONDENT_PROGRESS_KEY = 'respondentProgress'

interface Data {
  respondentProgress: RespondentProgress | undefined
  setRespondentProgress: (newProgress: RespondentProgress) => void
}

const useRespondentProgress: () => Data = () => {
  const [respondentProgress, setRespondentProgress] =
    useState<RespondentProgress>(() => {
      const respondentProgressString = window.localStorage.getItem(
        RESPONDENT_PROGRESS_KEY
      )

      return respondentProgressString
        ? JSON.parse(respondentProgressString)
        : undefined
    })

  const setRespondentProgressInternal: (
    newProgress: RespondentProgress
  ) => void = useCallback((newProgress) => {
    window.localStorage.setItem(
      RESPONDENT_PROGRESS_KEY,
      JSON.stringify(newProgress)
    )
    setRespondentProgress(newProgress)
  }, [])

  return {
    respondentProgress,
    setRespondentProgress: setRespondentProgressInternal
  }
}

export default useRespondentProgress
