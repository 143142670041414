import { EntryType } from 'model/common'
import {
  RenderedQuestionnaire,
  QuestionItem,
  MatrixItem,
  QuestionnaireEntry,
  TextCardItem
} from 'model/questionnaire'
import { RespondentProgress } from 'model/respondentProgress'
import { RESPONDENT_CHOICE_KEY } from './constants'
import getPersistedRespondentChoices from './getPersistedRespondentChoices'

export const getEntryId = (
  questionnaireEntry: QuestionnaireEntry | undefined
) => {
  if (
    questionnaireEntry &&
    questionnaireEntry.entryType === EntryType.QuestionEntryType
  ) {
    return (questionnaireEntry.entryItem as QuestionItem).question.questionId
  }
  if (
    questionnaireEntry &&
    questionnaireEntry.entryType === EntryType.MatrixEntryType
  ) {
    return (questionnaireEntry.entryItem as MatrixItem).matrixTitle
      .matrixTitleId
  }
  if (
    questionnaireEntry &&
    questionnaireEntry.entryType === EntryType.TextCardEntryType
  ) {
    return (questionnaireEntry.entryItem as TextCardItem).textCard.textCardId
  }
  return ''
}

export const clearResponseChoices: () => void = () => {
  window.localStorage.removeItem(RESPONDENT_CHOICE_KEY)
}

const removeRespondentChoiceFromLocalstorage = (
  data: RenderedQuestionnaire | undefined,
  respondentProgress: RespondentProgress | undefined
) => {
  if (respondentProgress) {
    const persistenRespondentChoices = getPersistedRespondentChoices()

    const { currentEntryPosition } = respondentProgress

    const entry = data?.questionnaire?.entries.find(
      (entry) => entry.entryPosition === currentEntryPosition - 1
    )

    const entryId = getEntryId(entry)

    const newPersistenRespondentChoices = persistenRespondentChoices.filter(
      (respondentChoice) => {
        return (
          respondentChoice.questionId !== entryId &&
          respondentChoice.matrixTitleId !== entryId
        )
      }
    )
    window.localStorage.setItem(
      RESPONDENT_CHOICE_KEY,
      JSON.stringify(newPersistenRespondentChoices)
    )
  }
}

export default removeRespondentChoiceFromLocalstorage
