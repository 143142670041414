import { SelectedResponse, SingleChoice } from '@focaldata/cin-ui-components'
import {
  CfmContext,
  CfmData
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import persistRespondentChoice from 'utils/persistRespondentChoices'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { QuestionItem, QuestionnaireEntry } from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { createResponseOptionChoice } from 'utils/responseChoice'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { setHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'
import {
  entryHasNoNonDefaultResponseOptions,
  getEntryItemWithFilteredMaskedResponseOptions
} from 'utils/responseOptionsMaskingLogic'

interface Props {
  entry: QuestionnaireEntry
}

// TODO: may want to merge this and multiple choice?
const SingleChoiceEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const progress = useEntryProgress(entry)
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const { sendResponseOptionChoices } = useSendResponseChoice()
  const { formatMessage } = useIntl()
  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: (
      <FormattedMessage
        id="question_singleChoiceInstructions"
        defaultMessage="Select one option"
      />
    ),
    typeYourAnswerHere: formatMessage({
      id: 'question_freeTextPlaceholder',
      defaultMessage: 'Type your answer here...'
    })
  }

  const getResponseOptionChoices: (
    selectedResponse: SelectedResponse
  ) => ResponseOptionChoice[] = (selectedResponse) => {
    return (
      entryItem.responseOptions?.map((responseOption) => {
        const selected =
          responseOption.position === selectedResponse.selectedPosition
        return createResponseOptionChoice(
          responseOption,
          selected ? selectedResponse : undefined
        )
      }) || []
    )
  }

  const getRespondentChoice = (
    entryItem: QuestionItem,
    selectedResponse: SelectedResponse
  ): PersistentRespondentChoice => {
    const responseOptionChoiceId =
      entryItem.responseOptions
        ?.map((responseOption) => {
          const selected =
            responseOption.position === selectedResponse.selectedPosition
          return selected ? responseOption.option.responseOptionId : undefined
        })
        .filter((responseOptionId) => responseOptionId) || []

    return {
      questionId: entryItem.question.questionId,
      responseChoiceIds: responseOptionChoiceId
    }
  }

  const handleNext: (selectedResponse: SelectedResponse) => void = (
    selectedResponse
  ) => {
    const respondentChoice = getRespondentChoice(entryItem, selectedResponse)
    persistRespondentChoice(respondentChoice)

    if (!preview) {
      sendResponseOptionChoices(
        entryItem.question.questionId,
        entry,
        entry.entryType,
        renderingDateTime,
        getResponseOptionChoices(selectedResponse)
      )
    }

    getNextEntryPosition([selectedResponse.selectedPosition])
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    setHiddenByDisplayLogic(entryItem.question.questionId)
    getNextEntryPosition()
  }

  const entryItemWithFilteredResponseOptions =
    getEntryItemWithFilteredMaskedResponseOptions(entryItem)

  if (
    entryHasNoNonDefaultResponseOptions(
      entryItemWithFilteredResponseOptions.responseOptions
    )
  ) {
    setHiddenByDisplayLogic(entryItem.question.questionId)
    getNextEntryPosition()
  }

  return (
    <SingleChoice
      item={entryItemWithFilteredResponseOptions}
      loc={loc}
      progress={progress}
      preview={preview}
      next={{ disabled: false, loading: false, onNext: handleNext }}
      dataCy="response-option"
    />
  )
}

export default SingleChoiceEntry
