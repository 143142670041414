import React from 'react'
import { QuestionnaireEntry } from 'model/questionnaire'
import {
  Text,
  TextDisplay,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'

export const getForkDetailsForEntry: (
  entry: QuestionnaireEntry | undefined
) => JSX.Element | string | undefined = (entry) => {
  return entry && entry.renderedForks && entry.renderedForks.length > 0 ? (
    <>
      Shown to{' '}
      {entry?.renderedForks?.map((fork, index) => (
        <Text
          highlight={TextHighlight.Background}
          size={TextSize.s}
          display={TextDisplay.Inline}
          weight={TextWeight.SemiBold}
        >
          {`${fork.forkName}: ${fork.branchLabel}${
            index < (entry.renderedForks?.length || 0) - 1 ? ', ' : ' '
          }`}
        </Text>
      ))}
    </>
  ) : undefined
}
