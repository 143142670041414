import { additionalPalette, makeStyles } from '@focaldata/cin-ui-components'
import ErrorOverlay from 'controls/ErrorOverlay/ErrorOverlay'
import { EntryType } from 'model/common'
import { QuestionnaireEntry } from 'model/questionnaire'
import React from 'react'
import MatrixEntry from './MatrixEntry'
import QuestionEntry from './QuestionEntry'
import TextInstructionsEntry from './TextInstructionsEntry'

const useStyles = makeStyles()(() => {
  return {
    page: {
      height: '100vh',
      backgroundColor: additionalPalette.lightBlue.main
    }
  }
})

interface Props {
  entry: QuestionnaireEntry
}

const getEntryByType: (entry: QuestionnaireEntry) => JSX.Element = (entry) => {
  switch (entry.entryType) {
    case EntryType.MatrixEntryType:
      return <MatrixEntry entry={entry} />
    case EntryType.QuestionEntryType:
      return <QuestionEntry entry={entry} />
    case EntryType.TextCardEntryType:
      return <TextInstructionsEntry entry={entry} />
    default:
      // TODO: this should also probably log a Sentry error
      return <ErrorOverlay />
  }
}

const Entry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const { classes } = useStyles()

  const component = getEntryByType(entry)

  return <div className={classes.page}>{component}</div>
}

export default Entry
