import { AxiosError } from 'axios'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { EntryType } from 'model/common'
import {
  Matrix,
  ResponseChoice,
  ResponseOptionChoice
} from 'model/responseChoice'
import { useContext } from 'react'
import { RESPONSE_CHOICE_API_URL } from 'utils/constants'
import { getNowTimeString, getUtcOffset } from 'utils/datetime'
import { useMandatoryQueryParams } from './useMandatoryQueryParams'
import usePostCallback from './usePostCallback'

interface Data {
  error: AxiosError | undefined
  sendResponseOptionChoices: (
    questionId: string,
    entry: EntryPositionProps,
    entryType: EntryType,
    renderingDateTime: string,
    responseChoices: ResponseOptionChoice[],
    matrix?: Matrix
  ) => void
}

interface EntryPositionProps {
  entryPosition: number
  renderedEntryPosition: number
  contextPosition: number
  renderedContextPosition: number
}

const endpoint = `${process.env.REACT_APP_DOMAIN}${RESPONSE_CHOICE_API_URL}`

const useSendResponseChoice: () => Data = () => {
  const mandatoryQueryParams = useMandatoryQueryParams()
  const {
    respondentProgress: [progress]
  } = useContext<AppState>(AppStateContext)
  const [error, sendResponseChoice] = usePostCallback<ResponseChoice>()

  const sendResponseOptionChoices: (
    questionId: string,
    entry: EntryPositionProps,
    entryType: EntryType,
    renderingDateTime: string,
    responseChoices: ResponseOptionChoice[],
    matrix?: Matrix
  ) => void = (
    questionId,
    entry,
    entryType,
    renderingDateTime,
    responseChoices,
    matrix
  ) => {
    if (!mandatoryQueryParams) {
      // TODO: log to sentry? should never get here
      return
    }

    const payload: ResponseChoice = {
      surveyId: mandatoryQueryParams.surveyId,
      panelSupplierCode: mandatoryQueryParams.panelSupplier,
      respondentId: mandatoryQueryParams.respondentId,
      isPreview: !!mandatoryQueryParams.preview,
      enteringDatetime: renderingDateTime,
      submissionDatetime: getNowTimeString(),
      questionId,
      matrix,
      entryPosition: entry.entryPosition,
      renderedEntryPosition: entry.renderedEntryPosition,
      previousEntryPosition: progress?.previousEntryPosition || -1,
      contextPosition: entry.contextPosition,
      renderedContextPosition: entry.renderedContextPosition,
      entryType,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      responseChoices,
      utcOffset: getUtcOffset()
    }

    sendResponseChoice(payload, endpoint)
  }

  return { error, sendResponseOptionChoices }
}

export default useSendResponseChoice
