import ErrorOverlay from 'controls/ErrorOverlay/ErrorOverlay'
import {
  QuestionItem,
  QuestionnaireEntry,
  QuestionTypes,
  SettingValues
} from 'model/questionnaire'
import React from 'react'
import FreeTextEntry from './FreeTextEntry'
import MultipleChoiceEntry from './MultipleChoiceEntry'
import RankedEntry from './RankedEntry'
import ScaleEntry from './ScaleEntry'
import SingleChoiceEntry from './SingleChoiceEntry'

interface Props {
  entry: QuestionnaireEntry
}

const QuestionEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props

  const questionEntry: QuestionItem = entry.entryItem as QuestionItem

  switch (questionEntry.questionTypeCode) {
    case QuestionTypes.FreeText:
      return <FreeTextEntry entry={entry} />
    case QuestionTypes.Ranked:
      return <RankedEntry entry={entry} />
    case QuestionTypes.Scale:
      return <ScaleEntry entry={entry} />
    case QuestionTypes.StandardAudience:
    case QuestionTypes.CustomAudience:
    case QuestionTypes.BasicChoice: {
      const isSingleChoice = questionEntry.settingValues?.some(
        (settingValue) => settingValue.value === SettingValues.SingleChoice
      )

      if (isSingleChoice) {
        return <SingleChoiceEntry entry={entry} />
      }
      return <MultipleChoiceEntry entry={entry} />
    }
    default:
      // TODO: this should also probably log a Sentry error
      return <ErrorOverlay />
  }
}

export default QuestionEntry
