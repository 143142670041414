import { SelectedSliderResponse, Slider } from '@focaldata/cin-ui-components'
import {
  CfmContext,
  CfmData
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import {
  QuestionItem,
  QuestionnaireEntry,
  SettingCodes,
  SettingValues
} from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { getNowTimeString } from 'utils/datetime'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'

interface Props {
  entry: QuestionnaireEntry
}

const ScaleEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const progress = useEntryProgress(entry)
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const { sendResponseOptionChoices } = useSendResponseChoice()

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: (
      <FormattedMessage
        id="question_scaleChoiceInstructions"
        defaultMessage="Drag the button to rate"
      />
    )
  }

  const getResponseOptionChoices: (
    selectedSliderResponse: SelectedSliderResponse
  ) => ResponseOptionChoice = (selectedSliderResponse) => {
    return {
      value: selectedSliderResponse.sliderOutput?.toString() || '0',
      responseDatetime: selectedSliderResponse.timestamp || getNowTimeString()
    }
  }

  const handleNext: (selectedSliderResponse: SelectedSliderResponse) => void = (
    selectedSliderResponse
  ) => {
    if (preview) {
      preview.onNavigateForward()
    } else {
      sendResponseOptionChoices(
        entryItem.question.questionId,
        entry,
        entry.entryType,
        renderingDateTime,
        [getResponseOptionChoices(selectedSliderResponse)]
      )
      getNextEntryPosition()
    }
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    getNextEntryPosition()
  }

  const shouldShowLabels =
    entryItem.settingValues?.find(
      (settingValue) => settingValue.code === SettingCodes.SliderLabels
    )?.value === SettingValues.Enabled

  return (
    <Slider
      shouldShowLabels={shouldShowLabels}
      key={entry.entryNumber}
      item={entryItem}
      loc={loc}
      preview={preview}
      progress={progress}
      next={{ disabled: false, loading: false, onNext: handleNext }}
    />
  )
}

export default ScaleEntry
