import { makeStyles, Theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(11)
      },
      padding: theme.spacing(5, 4)
    },
    image: {
      width: '160px',
      marginBottom: theme.spacing(3)
    },
    title: {
      marginBottom: theme.spacing(1)
    },
    subtitle: {
      marginBottom: theme.spacing(5)
    },
    visual: {
      marginBottom: theme.spacing(3)
    }
  }
})

export default useStyles
