import enAU from './en-AU.json'
import enCA from './en-CA.json'
import enGB from './en-GB.json'
import enIE from './en-IE.json'
import enIN from './en-IN.json'
import enUS from './en-US.json'
import enZA from './en-ZA.json'
import esAR from './es-AR.json'
import esES from './es-ES.json'
import frFR from './fr-FR.json'
import hrHR from './hr-HR.json'
import itIT from './it-IT.json'
import nlNL from './nl-NL.json'
import ptBR from './pt-BR.json'
import trTR from './tr-TR.json'
import deDE from './de-DE.json'
import deAT from './de-AT.json'
import esMX from './es-MX.json'
import esCO from './es-CO.json'
import daDK from './da-DK.json'
import elGR from './el-GR.json'
import hiIN from './hi-IN.json'
import huHU from './hu-HU.json'
import jaJP from './ja-JP.json'
import koKR from './ko-KR.json'
import zhCN from './zh-CN.json'
import nbNO from './nb-NO.json'
import plPL from './pl-PL.json'
import roRO from './ro-RO.json'
import ruRU from './ru-RU.json'
import svSE from './sv-SE.json'
import thTH from './th-TH.json'
import frBE from './fr-BE.json'
import enSG from './en-SG.json'
import enHK from './en-HK.json'
import csCZ from './cs-CZ.json'
import arAE from './ar-AE.json'
import arSA from './ar-SA.json'
import enIL from './en-IL.json'
import viVN from './vi-VN.json'
import zhTW from './zh_TW.json'

export default {
  'en-AU': enAU,
  'en-CA': enCA,
  'en-GB': enGB,
  'en-IE': enIE,
  'en-IN': enIN,
  'en-US': enUS,
  'en-ZA': enZA,
  'es-AR': esAR,
  'es-ES': esES,
  'es-CO': esCO,
  'fr-FR': frFR,
  'hr-HR': hrHR,
  'it-IT': itIT,
  'nl-NL': nlNL,
  'pt-BR': ptBR,
  'tr-TR': trTR,
  'de-DE': deDE,
  'de-AT': deAT,
  'es-MX': esMX,
  'da-DK': daDK,
  'el-GR': elGR,
  'hi-IN': hiIN,
  'hu-HU': huHU,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'zh-CN': zhCN,
  'nb-NO': nbNO,
  'pl-PL': plPL,
  'ro-RO': roRO,
  'ru-RU': ruRU,
  'sv-SE': svSE,
  'th-TH': thTH,
  'fr-BE': frBE,
  'en-SG': enSG,
  'en-HK': enHK,
  'cs-CZ': csCZ,
  'ar-AE': arAE,
  'ar-SA': arSA,
  'en-IL': enIL,
  'vi-VN': viVN,
  'zh-TW': zhTW
}
