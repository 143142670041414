import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { RESPONDENT_CHOICE_KEY } from './constants'
import getPersistedRespondentChoices from './getPersistedRespondentChoices'

const persistenRespondentChoices = (
  respondentChoice: PersistentRespondentChoice
) => {
  const currentPersistentRespondentChoices = getPersistedRespondentChoices()

  currentPersistentRespondentChoices.push({
    questionId: respondentChoice.questionId,
    matrixTitleId: respondentChoice.matrixTitleId,
    responseChoiceIds: respondentChoice.responseChoiceIds
  })

  window.localStorage.setItem(
    RESPONDENT_CHOICE_KEY,
    JSON.stringify(currentPersistentRespondentChoices)
  )
}

export default persistenRespondentChoices
