import { makeStyles } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => {
  return {
    image: {
      width: '160px'
    }
  }
})

export default useStyles
