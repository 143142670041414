import { Ranked, SelectedRankedResponse } from '@focaldata/cin-ui-components'
import {
  CfmData,
  CfmContext
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import {
  QuestionItem,
  QuestionnaireEntry,
  SettingCodes
} from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import {
  entryHasNoNonDefaultResponseOptions,
  getEntryItemWithFilteredMaskedResponseOptions
} from 'utils/responseOptionsMaskingLogic'
import { getSetting } from 'utils/question'
import { createResponseOptionChoiceRanked } from 'utils/responseChoice'

interface Props {
  entry: QuestionnaireEntry
}

const getRankedChoiceRequisiteInstructions: (
  requisite: string | undefined,
  responseOptionsNo: number | undefined
) => string = (requisite, responseOptionsNo) =>
  requisite === undefined ||
  (requisite && responseOptionsNo && Number(requisite) >= responseOptionsNo)
    ? 'question_rankedPleaseRankAll'
    : 'question_rankedPleaseRankTop'

const RankedEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem
  const progress = useEntryProgress(entry)
  const requisite = getSetting(
    entryItem.settingValues,
    SettingCodes.ChoiceRequisite
  )
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const { sendResponseOptionChoices } = useSendResponseChoice()
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)

  const getResponseOptionChoices: (
    selectedResponses: SelectedRankedResponse[]
  ) => ResponseOptionChoice[] = (selectedResponses) => {
    if (entryItem.responseOptions) {
      const rankedOptions = selectedResponses.map((selectedResponse, index) => {
        const responseOption = entryItem.responseOptions?.find(
          (ro) => ro.position === selectedResponse.selectedPosition
        )
        return createResponseOptionChoiceRanked(
          responseOption,
          selectedResponse,
          index
        )
      })
      const unrankedOptions = entryItem.responseOptions
        ?.filter(
          (ro) =>
            !selectedResponses.some((sr) => sr.selectedPosition === ro.position)
        )
        .map((ro) => {
          return createResponseOptionChoiceRanked(ro, undefined, undefined)
        })

      return [...rankedOptions, ...unrankedOptions]
    }

    return []
  }

  const handleNext: (selectedResponses: SelectedRankedResponse[]) => void = (
    selectedResponses
  ) => {
    if (!preview) {
      sendResponseOptionChoices(
        entryItem.question.questionId,
        entry,
        entry.entryType,
        renderingDateTime,
        getResponseOptionChoices(selectedResponses)
      )
    }
    getNextEntryPosition()
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    getNextEntryPosition()
  }

  const entryItemWithFilteredResponseOptions =
    getEntryItemWithFilteredMaskedResponseOptions(entryItem)

  if (
    entryHasNoNonDefaultResponseOptions(
      entryItemWithFilteredResponseOptions.responseOptions
    )
  ) {
    getNextEntryPosition()
  }

  const responseOptionsNo =
    entryItemWithFilteredResponseOptions.responseOptions?.length

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: (
      <FormattedMessage
        id={getRankedChoiceRequisiteInstructions(requisite, responseOptionsNo)}
        defaultMessage="Please rank all options"
        values={{ count: requisite }}
      />
    ),
    tapToRemove: (
      <FormattedMessage
        id="question_rankedTapToRemove"
        defaultMessage="TAP TO REMOVE"
      />
    ),
    tapToSelect: (
      <FormattedMessage
        id="question_rankedTapToSelect"
        defaultMessage="TAP TO SELECT"
      />
    ),
    dragToReorder: (
      <FormattedMessage
        id="question_rankedDragToReorder"
        defaultMessage="DRAG TO REORDER"
      />
    )
  }

  return (
    <Ranked
      item={entryItemWithFilteredResponseOptions}
      loc={loc}
      progress={progress}
      preview={preview}
      next={{ disabled: false, loading: false, onNext: handleNext }}
      dataCy="response-option"
    />
  )
}

export default RankedEntry
