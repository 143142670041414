import axios from 'axios'
import { RenderedQuestionnaire } from 'model/questionnaire'
import { useEffect, useState } from 'react'
import { RESPONDENT_QUESTIONNAIRE_API_URL } from 'utils/constants'
import {
  MandatoryQueryParams,
  useMandatoryQueryParams
} from './useMandatoryQueryParams'

interface Data {
  renderedQuestionnaire: RenderedQuestionnaire | undefined
  loading: boolean
  error: boolean
}

const useRenderedQuestionnaire: () => Data = () => {
  const mandatoryQueryParams = useMandatoryQueryParams()
  const [data, setData] = useState<Data>({
    renderedQuestionnaire: undefined,
    loading: true,
    error: false
  })

  useEffect(() => {
    const fetchRenderedQuestionnaire: (
      mandatoryQueryParams: MandatoryQueryParams
    ) => void = async (mandatoryQueryParams) => {
      const { surveyId, respondentId, panelSupplier, preview } =
        mandatoryQueryParams
      try {
        const { data }: { data: RenderedQuestionnaire } = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_DOMAIN}${RESPONDENT_QUESTIONNAIRE_API_URL}?surveyId=${surveyId}&panelSupplierCode=${panelSupplier}&respondentId=${respondentId}&preview=${preview}`,
          timeout: 30000
        })
        setData({
          renderedQuestionnaire: data,
          loading: false,
          error: false
        })
      } catch (error) {
        setData({
          renderedQuestionnaire: undefined,
          loading: false,
          error: true
        })
      }
    }

    if (mandatoryQueryParams) {
      fetchRenderedQuestionnaire(mandatoryQueryParams)
    }
  }, [mandatoryQueryParams])

  return data
}

export default useRenderedQuestionnaire
