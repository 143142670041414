import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { RESPONDENT_CHOICE_KEY } from './constants'

const getPersistedRespondentChoices = () => {
  const persistedRespondentChoicesString =
    window.localStorage.getItem(RESPONDENT_CHOICE_KEY) || ''

  const persistedRespondentChoices: PersistentRespondentChoice[] =
    persistedRespondentChoicesString
      ? JSON.parse(persistedRespondentChoicesString)
      : []

  return persistedRespondentChoices
}

export default getPersistedRespondentChoices
