import { Button, Checkbox } from '@focaldata/cin-ui-components'
import { PfmContext, PfmData } from 'containers/PageFlowManager/PageFlowManager'
import { AppState, AppStateContext } from 'containers/State/AppState'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import { EntryType } from 'model/common'
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getNowTimeString } from 'utils/datetime'
import TermsPageLayout from 'layouts/TermsPageLayout/TermsPageLayout'
import Terms from 'controls/Terms/Terms'

const renderingDatetime = getNowTimeString()
const DRAFT_PRIVACY_QUESTION_ID = '85f91009-66d0-4700-880a-753ee95238b6'

const TermsPage: React.FC = () => {
  const { next } = useContext<PfmData>(PfmContext)
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false)
  const { sendResponseOptionChoices } = useSendResponseChoice()
  const { respondentProgress } = useContext<AppState>(AppStateContext)
  const [progress, setProgress] = respondentProgress

  const handleClickNext: () => void = () => {
    sendResponseOptionChoices(
      DRAFT_PRIVACY_QUESTION_ID,
      {
        entryPosition: -1,
        renderedEntryPosition: -1,
        contextPosition: -1,
        renderedContextPosition: -1
      },
      EntryType.TermsEntryType,
      renderingDatetime,
      []
    )

    if (progress) {
      const newRespondentProgress = { ...progress }
      newRespondentProgress.isTermsAgreed = true
      setProgress(newRespondentProgress)
    }

    next()
  }

  return (
    <TermsPageLayout
      title={
        <FormattedMessage
          id="termsPage_title"
          defaultMessage="TERMS AND CONDITIONS OF USE OF FOCALDATA.COM"
          values={{
            website: (
              <a href="https://focaldata.com" rel="noreferrer" target="_blank">
                FOCALDATA.COM
              </a>
            )
          }}
        />
      }
      topCheckbox={
        <Checkbox
          checked={termsAgreed}
          onChange={() => {
            setTermsAgreed(!termsAgreed)
          }}
          dataCy="terms-checkbox-0"
          label={
            <FormattedMessage
              id="termsPage_agree"
              defaultMessage="I agree that any personal information provided in this survey can be used for the purpose(s) mentioned in the Privacy Notice"
            />
          }
        />
      }
      topAction={
        <Button disabled={!termsAgreed} onClick={handleClickNext} fullWidth>
          <FormattedMessage id="termsPage_begin" defaultMessage="Begin" />
        </Button>
      }
      terms={<Terms />}
      bottomCheckbox={
        <Checkbox
          checked={termsAgreed}
          onChange={() => {
            setTermsAgreed(!termsAgreed)
          }}
          label={
            <FormattedMessage
              id="termsPage_agree"
              defaultMessage="I agree that any personal information provided in this survey can be used for the purpose(s) mentioned in the Privacy Notice"
            />
          }
        />
      }
      bottomAction={
        <Button disabled={!termsAgreed} onClick={handleClickNext} fullWidth>
          <FormattedMessage id="termsPage_begin" defaultMessage="Begin" />
        </Button>
      }
    />
  )
}

export default TermsPage
