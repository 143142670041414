import { MultipleChoice, SelectedResponse } from '@focaldata/cin-ui-components'
import {
  CfmData,
  CfmContext
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import {
  QuestionItem,
  QuestionnaireEntry,
  SettingCodes
} from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getMultipleChoiceInstructions, getSetting } from 'utils/question'
import { createResponseOptionChoice } from 'utils/responseChoice'
import persistRespondentChoice from 'utils/persistRespondentChoices'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { setHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'
import {
  entryHasNoNonDefaultResponseOptions,
  getEntryItemWithFilteredMaskedResponseOptions
} from 'utils/responseOptionsMaskingLogic'

interface Props {
  entry: QuestionnaireEntry
}

const MultipleChoiceEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem
  const limit = getSetting(entryItem.settingValues, SettingCodes.ChoiceLimit)
  const intervalMin =
    Number(
      getSetting(entryItem.settingValues, SettingCodes.ChoiceIntervalMin)
    ) ?? undefined
  const intervalMax =
    Number(
      getSetting(entryItem.settingValues, SettingCodes.ChoiceIntervalMax)
    ) ?? undefined

  const progress = useEntryProgress(entry)
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const { sendResponseOptionChoices } = useSendResponseChoice()
  const { formatMessage } = useIntl()

  const getResponseOptionChoices: (
    selectedResponses: SelectedResponse[]
  ) => ResponseOptionChoice[] = (selectedResponses) => {
    return (
      entryItem.responseOptions?.map((responseOption) => {
        const selectedResponse = selectedResponses.find(
          (sr) => sr.selectedPosition === responseOption.position
        )
        return createResponseOptionChoice(responseOption, selectedResponse)
      }) || []
    )
  }

  const getRespondentChoice = (
    entryItem: QuestionItem,
    selectedResponses: SelectedResponse[]
  ): PersistentRespondentChoice => {
    const responseOptionChoiceId =
      entryItem.responseOptions
        ?.map((responseOption) => {
          const selectedResponse = selectedResponses.find(
            (sr) => sr.selectedPosition === responseOption.position
          )
          return selectedResponse
            ? responseOption.option.responseOptionId
            : undefined
        })
        .filter((responseOptionId) => responseOptionId) || []

    return {
      questionId: entryItem.question.questionId,
      responseChoiceIds: responseOptionChoiceId
    }
  }

  const handleNext: (selectedResponses: SelectedResponse[]) => void = (
    selectedResponses
  ) => {
    const respondentChoice = getRespondentChoice(entryItem, selectedResponses)
    persistRespondentChoice(respondentChoice)

    if (!preview) {
      sendResponseOptionChoices(
        entryItem.question.questionId,
        entry,
        entry.entryType,
        renderingDateTime,
        getResponseOptionChoices(selectedResponses)
      )
    }

    const selectedResponseOptionPositions = selectedResponses.map(
      (selectedResponse) => selectedResponse.selectedPosition
    )
    getNextEntryPosition(selectedResponseOptionPositions)
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    setHiddenByDisplayLogic(entryItem.question.questionId)
    getNextEntryPosition()
  }

  const entryItemWithFilteredResponseOptions =
    getEntryItemWithFilteredMaskedResponseOptions(entryItem)

  if (
    entryHasNoNonDefaultResponseOptions(
      entryItemWithFilteredResponseOptions.responseOptions
    )
  ) {
    setHiddenByDisplayLogic(entryItem.question.questionId)
    getNextEntryPosition()
  }

  const roCount = entryItem.responseOptions?.length || 0
  const filteredRoCount =
    entryItemWithFilteredResponseOptions.responseOptions?.length || 0
  const maskedRos = roCount - filteredRoCount
  const updatedMax = intervalMax ? intervalMax - maskedRos : undefined

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: (
      <FormattedMessage
        id={getMultipleChoiceInstructions(
          limit,
          intervalMin,
          updatedMax,
          filteredRoCount
        )}
        defaultMessage="Select up to {count} answers"
        values={{
          count: <b>{limit ?? updatedMax}</b>,
          min: <b>{intervalMin}</b>
        }}
      />
    ),
    typeYourAnswerHere: formatMessage({
      id: 'question_freeTextPlaceholder',
      defaultMessage: 'Type your answer here...'
    })
  }

  return (
    <MultipleChoice
      item={entryItemWithFilteredResponseOptions}
      loc={loc}
      progress={progress}
      preview={preview}
      next={{ disabled: false, loading: false, onNext: handleNext }}
      dataCy="response-option"
    />
  )
}

export default MultipleChoiceEntry
