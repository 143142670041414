import { Matrix, SelectedResponse } from '@focaldata/cin-ui-components'
import {
  CfmData,
  CfmContext
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import {
  MatrixItem,
  QuestionnaireEntry,
  SettingCodes
} from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  getMultipleChoiceInstructions,
  getMultipleChoiceMatrixDesktopInstructions,
  getSetting
} from 'utils/question'
import { createResponseOptionChoice } from 'utils/responseChoice'
import persistRespondentChoice from 'utils/persistRespondentChoices'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { setHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'
import {
  entryHasNoMatrixRows,
  getEntryItemWithFilteredMaskedMatrixRows
} from 'utils/matrixRowsMaskingLogic'

interface Props {
  entry: QuestionnaireEntry
}

const scrollTop = () => {
  window.scroll({ top: 0, behavior: 'smooth' })
}

const MatrixEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as MatrixItem
  const limit = getSetting(entryItem.settingValues, SettingCodes.ChoiceLimit)
  const intervalMin =
    Number(
      getSetting(entryItem.settingValues, SettingCodes.ChoiceIntervalMin)
    ) ?? undefined
  const intervalMax =
    Number(
      getSetting(entryItem.settingValues, SettingCodes.ChoiceIntervalMax)
    ) ?? undefined
  const progress = useEntryProgress(entry)
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const { sendResponseOptionChoices } = useSendResponseChoice()
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    matrixInstructions: {
      singleSelectDesktop: (
        <FormattedMessage
          id="question_matrixSelectOneAnswerDesktop"
          defaultMessage="Select one answer per row"
        />
      ),
      multiSelectDesktop: (
        <FormattedMessage
          id={getMultipleChoiceMatrixDesktopInstructions(
            limit,
            intervalMin,
            intervalMax,
            entryItem.responseOptions.length
          )}
          defaultMessage="Select up to {count} answers per row"
          values={{
            count: <b>{limit ?? intervalMax}</b>,
            min: <b>{intervalMin}</b>
          }}
        />
      ),
      singleSelectMobile: (
        <FormattedMessage
          id="question_singleChoiceInstructions"
          defaultMessage="Select one answer"
        />
      ),
      multiSelectMobile: (
        <FormattedMessage
          id={getMultipleChoiceInstructions(
            limit,
            intervalMin,
            intervalMax,
            entryItem.responseOptions.length
          )}
          defaultMessage="Select up to {count} answers"
          values={{
            count: <b>{limit ?? intervalMax}</b>,
            min: <b>{intervalMin}</b>
          }}
        />
      )
    }
  }

  const getResponseOptionChoices: (
    selectedResponses: SelectedResponse[]
  ) => ResponseOptionChoice[] = (selectedResponses) => {
    return (
      entryItem.responseOptions?.map((responseOption) => {
        const selectedResponse = selectedResponses.find(
          (sr) => sr.selectedPosition === responseOption.position
        )
        return createResponseOptionChoice(responseOption, selectedResponse)
      }) || []
    )
  }

  const getRespondentChoice = (
    entryItem: MatrixItem,
    selectedResponses: SelectedResponse[],
    index: number
  ): PersistentRespondentChoice => {
    const responseOptionChoiceId =
      entryItem.responseOptions
        ?.map((responseOption) => {
          const selectedResponse = selectedResponses.find(
            (sr) => sr.selectedPosition === responseOption.position
          )
          return selectedResponse
            ? responseOption.option.responseOptionId
            : undefined
        })
        .filter((responseOptionId) => responseOptionId) || []

    return {
      questionId: entryItem.matrixRows[index].question.questionId,
      matrixTitleId: entryItem.matrixTitle.matrixTitleId,
      responseChoiceIds: responseOptionChoiceId
    }
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    setHiddenByDisplayLogic(entryItem.matrixTitle.matrixTitleId)
    getNextEntryPosition()
  }

  const entryItemWithFilteredMatrixRows =
    getEntryItemWithFilteredMaskedMatrixRows(entryItem)

  if (entryHasNoMatrixRows(entryItemWithFilteredMatrixRows.matrixRows)) {
    setHiddenByDisplayLogic(entryItem.matrixTitle.matrixTitleId)
    getNextEntryPosition()
    return null
  }

  const handleNext: (selectedResponses: SelectedResponse[][]) => void = (
    selectedResponses
  ) => {
    selectedResponses.forEach((_, index) => {
      const respondentChoice = getRespondentChoice(
        entryItemWithFilteredMatrixRows,
        selectedResponses[index],
        index
      )
      persistRespondentChoice(respondentChoice)
    })

    if (!preview) {
      selectedResponses.forEach((selectedResponseRow, index) => {
        sendResponseOptionChoices(
          entryItemWithFilteredMatrixRows.matrixRows[index].question.questionId,
          entry,
          entry.entryType,
          renderingDateTime,
          getResponseOptionChoices(selectedResponseRow),
          {
            matrixTitleId:
              entryItemWithFilteredMatrixRows.matrixTitle.matrixTitleId,
            rowPosition:
              entryItemWithFilteredMatrixRows.matrixRows[index].position,
            renderedRowPosition:
              entryItemWithFilteredMatrixRows.matrixRows[index].renderedPosition
          }
        )
      })
    }
    getNextEntryPosition()
  }

  return (
    <Matrix
      item={entryItemWithFilteredMatrixRows}
      progress={progress}
      preview={preview}
      loc={loc}
      next={{ disabled: false, loading: false, onNext: handleNext }}
      onMobileNext={scrollTop}
    />
  )
}

export default MatrixEntry
