import InfoPageLayout from 'layouts/InfoPageLayout/InfoPageLayout'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Text, TextHighlight, TextWeight } from '@focaldata/cin-ui-components'
import checkSrc from 'assets/check.svg'
import { useMandatoryQueryParams } from 'hooks/useMandatoryQueryParams'
import useSendRespondentStatus from 'hooks/useSendRespondentStatus'
import { AppState, AppStateContext } from 'containers/State/AppState'

const TIMER_START_VALUE = 3

const CompletePage: React.FC = () => {
  const [timer, setTimer] = useState<number>(TIMER_START_VALUE)
  const mandatoryQueryParams = useMandatoryQueryParams()
  const {
    respondentProgress: [progress]
  } = useContext<AppState>(AppStateContext)
  const { sendRespondentStatus } = useSendRespondentStatus()
  const CINT_REDIRECT_URL = `${process.env.REACT_APP_CINT_REDIRECT_URL}/${mandatoryQueryParams?.respondentId}`
  const isCompleted = progress?.isCompleted

  useEffect(() => {
    if (!mandatoryQueryParams?.preview) {
      if (timer === TIMER_START_VALUE) {
        sendRespondentStatus()
      }

      if (timer > 0) {
        setTimeout(() => {
          setTimer(timer - 1)
        }, 1000)
      } else {
        window.location.assign(CINT_REDIRECT_URL)
      }
    }
  })

  const locIds = new Map<string, string>([
    ['title', isCompleted ? 'completePage_thanks' : 'completePage_thanks2'],
    [
      'subtitle',
      isCompleted ? 'completePage_weAppreciate' : 'completePage_unfortunately'
    ],
    [
      'action',
      mandatoryQueryParams?.preview
        ? 'completePage_preview'
        : 'completePage_redirecting'
    ]
  ])

  return (
    <InfoPageLayout
      visual={<img src={checkSrc} alt="check icon" />}
      title={
        <FormattedMessage
          id={locIds.get('title')}
          defaultMessage="Thanks for completing our survey!"
        />
      }
      subtitle={
        <FormattedMessage
          id={locIds.get('subtitle')}
          defaultMessage="We appreciate you taking the time to answer the survey. To officially complete this survey, wait until you are redirected."
        />
      }
      action={
        <Text highlight={TextHighlight.Emphasis} weight={TextWeight.SemiBold}>
          <FormattedMessage
            id={locIds.get('action')}
            defaultMessage="Redirecting to Cint in {count} seconds"
            values={{ count: timer }}
          />
        </Text>
      }
    />
  )
}

export default CompletePage
