import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@focaldata/cin-ui-components'
import { PfmContext, PfmData } from 'containers/PageFlowManager/PageFlowManager'
import useSendRespondentInfo from 'hooks/useSendRespondentInfo'
import logoSrc from 'assets/fd-logo.png'
import InfoPageLayout from 'layouts/InfoPageLayout/InfoPageLayout'
import useStyles from './InfoPage.styles'

const InfoPage: React.FC = () => {
  const { classes } = useStyles()
  const { next } = useContext<PfmData>(PfmContext)
  const { sendRespondentInfo } = useSendRespondentInfo()

  const handleClick: () => void = () => {
    sendRespondentInfo()
    next()
  }

  return (
    <InfoPageLayout
      visual={
        <img src={logoSrc} alt="focaldata logo" className={classes.image} />
      }
      title={
        <FormattedMessage
          id="infoPage_welcome"
          defaultMessage="Welcome to your questionnaire brought to you by focaldata."
        />
      }
      subtitle={
        <FormattedMessage
          id="infoPage_pleaseClick"
          defaultMessage="Please click the button below to get started. All answers will be confidential."
        />
      }
      action={
        <Button onClick={handleClick}>
          <FormattedMessage
            id="infoPage_getStarted"
            defaultMessage="Get started"
          />
        </Button>
      }
    />
  )
}

export default InfoPage
