/* eslint-disable no-param-reassign */
import { useContext } from 'react'
import { RawDraftContentState } from '@focaldata/cin-ui-components'

import { EntryType } from 'model/common'
import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import {
  MatrixItem,
  QuestionItem,
  QuestionKind,
  QuestionnaireEntry,
  TextCardItem
} from 'model/questionnaire'
import getPersistedRespondentChoices from 'utils/getPersistedRespondentChoices'
import { AppState, AppStateContext } from 'containers/State/AppState'

const markerStoredRegex = /\{([AQ])-(\d+)\}/g
const markerViewRegex = /\{([AQ])(\d+)\}/g

const findSourceEntry = (
  sourceEntryNumber: number,
  sourceQuestionKind: QuestionKind,
  allEntries: QuestionnaireEntry[]
): QuestionnaireEntry | undefined => {
  return allEntries.find(
    (source) =>
      source.entryNumber === sourceEntryNumber &&
      source.questionKind === sourceQuestionKind
  )
}

const findResponseChoiceText = (sourceEntry: QuestionnaireEntry) => {
  const { renderedContextPosition } = sourceEntry
  const { question, responseOptions } = sourceEntry.entryItem as QuestionItem
  const responseChoiceIds =
    getPersistedRespondentChoices().find(
      (respondentChoice: PersistentRespondentChoice) =>
        respondentChoice.questionId === question.questionId
    )?.responseChoiceIds ?? []
  // source entry can be only single-select
  const responseChoiceId = responseChoiceIds[0]
  const chosenResponseOption = responseOptions?.find(
    (ro) => ro.option.responseOptionId === responseChoiceId
  )
  const shouldShowResponceValue =
    chosenResponseOption && responseChoiceIds.length === 1
  const prefix =
    sourceEntry.questionKind === QuestionKind.AudienceKind ? 'A' : 'Q'
  // take response option value if it was found; not found means that there was no choice by respondent yet (source goes after target or source was skipped) or source is multiselect
  // we show {Q<position>} or {A<position>} instead of selected response option text in this case
  return shouldShowResponceValue
    ? chosenResponseOption.option.value
    : `{${prefix}${renderedContextPosition + 1}}`
}

const getTitleMatches = (
  title: string,
  regex?: RegExp
): RegExpMatchArray | null => {
  return title.match(regex ?? markerStoredRegex)
}

interface SourceQuestionData {
  entryNumber: number
  questionKind: QuestionKind
  prefix: string
}
const getQuestionDataFromMarker = (marker: string): SourceQuestionData => {
  const prefix = marker[1]
  const entryNumber = Number(marker.slice(3, -1))
  const questionKind =
    prefix === 'A' ? QuestionKind.AudienceKind : QuestionKind.QuestionnaireKind
  return {
    entryNumber,
    questionKind,
    prefix
  }
}

const getTitleSubstitutions = (
  allEntries: QuestionnaireEntry[],
  rawTitle: string
): Record<string, string> => {
  const titleSubstitutions: Record<string, string> = {}

  getTitleMatches(rawTitle)?.forEach((marker) => {
    const { entryNumber, questionKind, prefix } =
      getQuestionDataFromMarker(marker)
    const sourceEntry = findSourceEntry(entryNumber, questionKind, allEntries)

    // empty curly braces will substitute marker in case source entry wasn't find (in case source entry was deleted, hidden by display logic, or exists on another fork branch)
    let responseChoiceText = '{}'

    if (sourceEntry) {
      responseChoiceText = findResponseChoiceText(sourceEntry)
      const markerView = `{${prefix}${sourceEntry.contextPosition + 1}}`
      titleSubstitutions[markerView] = responseChoiceText
    }

    titleSubstitutions[marker] = responseChoiceText
  })

  return titleSubstitutions
}

const findStartPatternIndexes = (str: string, pattern: string): number[] => {
  const regex = new RegExp(pattern, 'g')
  let match = regex.exec(str)
  const res = []

  while (match != null) {
    res.push(match.index)
    match = regex.exec(str)
  }

  return res
}

const getViewTitle = (
  allEntries: QuestionnaireEntry[],
  rawTitle: string
): string => {
  let viewTitle = rawTitle
  const titleSubstitutions = getTitleSubstitutions(allEntries, rawTitle)

  getTitleMatches(rawTitle)?.forEach((marker) => {
    viewTitle = viewTitle.replace(marker, titleSubstitutions[marker])
  })

  return viewTitle
}

const getViewTitleStyled = (
  allEntries: QuestionnaireEntry[],
  title: string,
  titleStyling: string
): string => {
  const titleMarkerMatches = [
    ...(getTitleMatches(titleStyling, markerViewRegex) || []),
    ...(getTitleMatches(titleStyling, markerStoredRegex) || [])
  ]

  const titleMatchesFromRawTitle = getTitleMatches(title)

  if (titleMarkerMatches.length === 0) {
    return titleStyling
  }

  const titleSubstitutions = getTitleSubstitutions(allEntries, title)
  const rawStyledContent: RawDraftContentState = JSON.parse(titleStyling)

  titleMarkerMatches?.forEach((marker, i) => {
    // this line of code can be used instead after backend will be able to handle title styling data in survey builder (update it in a similar way like entries positions after items reorder)
    // const responseText = titleSubstitutions[marker]
    const responseText =
      titleSubstitutions[marker] ||
      (titleMatchesFromRawTitle &&
        titleSubstitutions[titleMatchesFromRawTitle[i]])

    if (!responseText) {
      return
    }

    // each block represents one line of styled text
    rawStyledContent.blocks.forEach((block) => {
      const startIndexes = findStartPatternIndexes(block.text, marker)

      const responseMarkerLengthDiff = responseText.length - marker.length
      block.text = block.text.replaceAll(marker, responseText)

      // for each style adjust style values so that styles are applied to proper parts of the text
      // example: '{Q1} bold' - offset before bold text is 5
      // in case we substitute value to {Q1} and have 'response bold' as a result string, we should update offset so that it is 9 not 5 (4 chars difference between '{Q1}' and 'response' strings)
      block.inlineStyleRanges.forEach((styleRange) => {
        startIndexes.forEach((markerIndex, i) => {
          // marker has style
          if (
            styleRange.offset ===
            markerIndex + responseMarkerLengthDiff * i
          ) {
            // in case all the marker was styled response text should be styles as well. otherwise, style is removed
            styleRange.length =
              styleRange.length >= marker.length
                ? Math.max(responseText.length, styleRange.length)
                : 0
          }

          // styling applied to the text that goes after marker
          if (styleRange.offset > markerIndex + marker.length) {
            styleRange.offset += responseMarkerLengthDiff
          }

          // styling includes marker inside it
          if (
            styleRange.offset <= markerIndex &&
            styleRange.offset + styleRange.length >= markerIndex + marker.length
          ) {
            styleRange.offset += responseMarkerLengthDiff * i
            styleRange.length += responseMarkerLengthDiff
          }
        })
      })
    })
  })

  return JSON.stringify(rawStyledContent)
}

const getEntryWithViewTitle = (
  currentEntry: QuestionnaireEntry,
  allEntries: QuestionnaireEntry[]
): QuestionnaireEntry => {
  switch (currentEntry.entryType) {
    case EntryType.MatrixEntryType: {
      const entryItem = currentEntry.entryItem as MatrixItem
      const { matrixTitle } = entryItem
      const { title, titleStyling } = matrixTitle
      return {
        ...currentEntry,
        entryItem: {
          ...entryItem,
          matrixTitle: {
            ...matrixTitle,
            title: getViewTitle(allEntries, title),
            titleStyling:
              titleStyling &&
              getViewTitleStyled(allEntries, title, titleStyling)
          }
        }
      }
    }
    case EntryType.QuestionEntryType: {
      const entryItem = currentEntry.entryItem as QuestionItem
      const { question } = entryItem
      const { text, textStyling } = question
      return {
        ...currentEntry,
        entryItem: {
          ...entryItem,
          question: {
            ...question,
            text: getViewTitle(allEntries, text),
            titleStyling:
              textStyling && getViewTitleStyled(allEntries, text, textStyling)
          }
        }
      }
    }
    case EntryType.TextCardEntryType: {
      const entryItem = currentEntry.entryItem as TextCardItem
      const { textCard } = entryItem
      const { title, titleStyling } = textCard
      return {
        ...currentEntry,
        entryItem: {
          ...entryItem,
          textCard: {
            ...textCard,
            title: getViewTitle(allEntries, title),
            titleStyling:
              titleStyling &&
              getViewTitleStyled(allEntries, title, titleStyling)
          }
        }
      }
    }
    default:
      break
  }
  return currentEntry
}

const useQuestionPiping = (): QuestionnaireEntry | undefined => {
  const {
    respondentProgress: [respondentProgress],
    renderedQuestionnaire: [renderedQuestionnaire]
  } = useContext<AppState>(AppStateContext)

  if (!renderedQuestionnaire || !respondentProgress) {
    return undefined
  }

  const currentPosition = respondentProgress.currentEntryPosition
  const { entries } = renderedQuestionnaire.questionnaire
  const currentEntry: QuestionnaireEntry = entries[currentPosition]
  return getEntryWithViewTitle(currentEntry, entries)
}

export default useQuestionPiping
