import { useMemo } from 'react'
import {
  DeploymentEnvironment,
  getDeploymentEnvironment
} from 'utils/deployment'
import useQueryParameters from './useQueryParams'

const getCollect = (collectAsString: string | undefined | null): boolean => {
  if (!collectAsString) {
    const deploymentEnvironment = getDeploymentEnvironment()
    // collect by default if prod, hide by default if dev
    return deploymentEnvironment === DeploymentEnvironment.Prod
  }

  return collectAsString === 'true'
}

const useCollectDataParameter = (): boolean => {
  const queryParams = useQueryParameters()
  const collect = useMemo(
    () => getCollect(queryParams.get('collect')),
    [queryParams]
  )
  return collect
}

export default useCollectDataParameter
