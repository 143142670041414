import { PfmContext, PfmData } from 'containers/PageFlowManager/PageFlowManager'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { useMandatoryQueryParams } from 'hooks/useMandatoryQueryParams'
import { EntryType } from 'model/common'
import {
  QuestionItem,
  QuestionTypes,
  SettingCodes,
  SettingValues
} from 'model/questionnaire'
import React, { PropsWithChildren, useContext } from 'react'
import {
  RESPONDENT_CHOICE_KEY,
  ROUTE_TO_END_SURVEY_NUMBER
} from 'utils/constants'
import { noContextMethod } from 'utils/context'
import {
  clearQuestionsHiddenByDisplayLogic,
  removeHiddenByDisplayLogic
} from 'utils/hiddenByDisplayLogic'
import { getSetting } from 'utils/question'
import removeRespondentChoiceFromLocalstorage from 'utils/removeRespondentChoiceFromLocalstorage'
import { getForkDetailsForEntry } from './QuestionFlowManager.utils'

export interface CfmData {
  getNextEntryPosition: (selectedPositions?: number[]) => void
  preview?: {
    isPreview: boolean
    forkDetails?: JSX.Element | string
    onNavigateForward: () => void
    onNavigateBackward: () => void
    onClickRefresh: () => void
  }
}

export const CfmContext = React.createContext<CfmData>({
  getNextEntryPosition: noContextMethod
})

const QuestionFlowManager = (props: PropsWithChildren<{}>) => {
  const { children }: PropsWithChildren<{}> = props
  const mandatoryParams = useMandatoryQueryParams()
  const {
    respondentProgress: [respondentProgress, setRespondentProgress],
    renderedQuestionnaire: [data]
  } = useContext<AppState>(AppStateContext)
  const { next } = useContext<PfmData>(PfmContext)

  const setProgress: (nextEntryPosition: number) => void = (
    nextEntryPosition
  ) => {
    if (respondentProgress) {
      const newRespondentProgress = { ...respondentProgress }
      const isEndOfSurvey =
        nextEntryPosition === data?.questionnaire.entries.length ||
        nextEntryPosition === ROUTE_TO_END_SURVEY_NUMBER
      if (isEndOfSurvey) {
        newRespondentProgress.isCompleted = true
        next()
      } else {
        newRespondentProgress.previousEntryPosition =
          respondentProgress.currentEntryPosition
        newRespondentProgress.currentEntryPosition = nextEntryPosition
      }
      setRespondentProgress(newRespondentProgress)
    }
  }

  // TODO: move this to a hook
  const handleRouting: (selectedPositions?: number[]) => boolean = (
    selectedPositions
  ) => {
    if (respondentProgress && data && selectedPositions) {
      const currentQuestion =
        data.questionnaire.entries[respondentProgress.currentEntryPosition]
      if (currentQuestion.entryType === EntryType.QuestionEntryType) {
        const questionItem = currentQuestion.entryItem as QuestionItem

        const responseOptions = questionItem.responseOptions?.filter((ro) =>
          selectedPositions.includes(ro.position)
        )

        const targetNumbers = responseOptions
          ?.map((responseOption) => responseOption.route?.targetNumber)
          .filter((routeTargetNumber) => routeTargetNumber !== undefined)
        const targetNumber = targetNumbers && targetNumbers[0] // TODO: This does not address if there are multiple routes on a multiple question

        if (!targetNumber) {
          return false
        }

        if (targetNumber === ROUTE_TO_END_SURVEY_NUMBER) {
          setProgress(targetNumber)
          return true
        }

        const filteredQuestionnaireEntries =
          data?.questionnaire.entries?.filter(
            (entry) =>
              (entry.entryItem as QuestionItem).questionTypeCode !==
                QuestionTypes.StandardAudience &&
              (entry.entryItem as QuestionItem).questionTypeCode !==
                QuestionTypes.CustomAudience
          )
        const entryToRouteTo = filteredQuestionnaireEntries.find(
          (entry) => entry.entryNumber === targetNumber
        )

        if (entryToRouteTo) {
          setProgress(entryToRouteTo.renderedEntryPosition)
          return true
        }
      }
    }
    return false
  }

  const isScreenOut: (selectedPositions: number[]) => boolean = (
    selectedPositions
  ) => {
    if (respondentProgress && data) {
      const currentQuestion = data.questionnaire.entries[
        respondentProgress.currentEntryPosition
      ].entryItem as QuestionItem
      if (
        currentQuestion.questionTypeCode === QuestionTypes.StandardAudience ||
        currentQuestion.questionTypeCode === QuestionTypes.CustomAudience
      ) {
        // if all of the selected are screenouts, it's a screenout
        const selectedNonScreenOut = selectedPositions.filter(
          (sp) =>
            currentQuestion.responseOptions?.find((ro) => ro.position === sp)
              ?.option.isQualifying
        )

        if (selectedNonScreenOut.length === 0) {
          return true
        }
      }
    }

    return false
  }

  const handleScreenOut: (selectedPositions: number[]) => boolean = (
    selectedPositions
  ) => {
    const screenOut = isScreenOut(selectedPositions)

    if (screenOut && respondentProgress) {
      const newRespondentProgress = { ...respondentProgress }
      newRespondentProgress.isScreened = true
      setRespondentProgress(newRespondentProgress)
      next()
      return true
    }

    return false
  }

  const isQuotaFull: (selectedPosition: number) => boolean = (
    selectedPosition
  ) => {
    if (respondentProgress && data) {
      const currentQuestion = data.questionnaire.entries[
        respondentProgress.currentEntryPosition
      ].entryItem as QuestionItem
      if (
        currentQuestion.questionTypeCode === QuestionTypes.StandardAudience ||
        currentQuestion.questionTypeCode === QuestionTypes.CustomAudience
      ) {
        const responseOption = currentQuestion.responseOptions?.find(
          (ro) => ro.position === selectedPosition
        )
        const quota = data.quotaStatus.quotas.find(
          (quota) =>
            quota.key.questionId === currentQuestion.question.questionId &&
            quota.key.responseOptionId ===
              responseOption?.option.responseOptionId
        )
        const quotasEnabled =
          getSetting(currentQuestion.settingValues, SettingCodes.Quotas) ===
          SettingValues.Enabled
        if (responseOption && quota?.isFull && quotasEnabled) {
          return true
        }
      }
    }

    return false
  }

  const handleQuotaFull: (selectedPosition: number) => boolean = (
    selectedPosition
  ) => {
    const quotaFull = isQuotaFull(selectedPosition)

    if (quotaFull && respondentProgress) {
      const newRespondentProgress = { ...respondentProgress }
      newRespondentProgress.isQuotaFull = true
      setRespondentProgress(newRespondentProgress)
      next()
      return true
    }

    return false
  }

  const handleQualityTerminate: (selectedPositions: number[]) => boolean = (
    selectedPositions
  ) => {
    if (respondentProgress && data) {
      const currentQuestion = data.questionnaire.entries[
        respondentProgress.currentEntryPosition
      ].entryItem as QuestionItem
      if (
        currentQuestion.questionTypeCode === QuestionTypes.StandardAudience &&
        (isQuotaFull(selectedPositions[0]) || isScreenOut(selectedPositions))
      ) {
        const newRespondentProgress = { ...respondentProgress }
        newRespondentProgress.isQualityTerminated = true
        setRespondentProgress(newRespondentProgress)
        next()
        return true
      }
    }

    return false
  }

  const handleGoToNext: () => void = () => {
    if (respondentProgress) {
      setProgress(respondentProgress.currentEntryPosition + 1)
    }
  }

  const getNextEntryPosition: (selectedPositions?: number[]) => void = (
    selectedPositions
  ) => {
    if (!selectedPositions) {
      handleGoToNext()
      return
    }

    const wasRouted = handleRouting(selectedPositions)
    if (wasRouted) {
      return
    }

    // careful with the order here. qualityTerminate before screenOut or quotaFull
    const wasQualityTerminated = handleQualityTerminate(selectedPositions)
    if (wasQualityTerminated) {
      return
    }

    const wasScreenedOut = handleScreenOut(selectedPositions)
    if (wasScreenedOut) {
      return
    }

    const wasQuotaFull = handleQuotaFull(selectedPositions[0])
    if (wasQuotaFull) {
      return
    }

    handleGoToNext()
  }

  const handleRefresh = () => {
    setProgress(0)
    window.localStorage.removeItem(RESPONDENT_CHOICE_KEY)
    clearQuestionsHiddenByDisplayLogic()
  }

  const moveForward: () => void = () => {
    if (mandatoryParams?.preview && respondentProgress) {
      setProgress(respondentProgress.currentEntryPosition + 1)
    }
  }

  const moveBack: () => void = () => {
    if (mandatoryParams?.preview && respondentProgress) {
      setProgress(respondentProgress.currentEntryPosition - 1)
      removeRespondentChoiceFromLocalstorage(data, respondentProgress)
      removeHiddenByDisplayLogic(data, respondentProgress)
    }
  }

  const contextValue: CfmData = mandatoryParams?.preview
    ? {
        getNextEntryPosition,
        preview: {
          isPreview: true,
          forkDetails: getForkDetailsForEntry(
            data?.questionnaire.entries[
              respondentProgress?.currentEntryPosition || 0
            ]
          ),
          onNavigateForward: moveForward,
          onNavigateBackward: moveBack,
          onClickRefresh: handleRefresh
        }
      }
    : { getNextEntryPosition }

  return (
    <CfmContext.Provider value={contextValue}>{children}</CfmContext.Provider>
  )
}

export default QuestionFlowManager
