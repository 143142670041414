import React from 'react'

interface TermItem {
  id: string
  title: string
  body: JSX.Element
}

export const getTerms: TermItem[] = [
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1101',
    title: "What's in these terms?",
    body: (
      <>
        These terms tell you the rules for using our website focaldata.com,
        participating in surveys we send to you, and using content to which you
        may be directed (<span>our site</span>).
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1102',
    title: 'Who we are and how to contact us',
    body: (
      <>
        focaldata.com is a site operated by Focaldata Limited (
        <span>&quot;We&quot;</span>). We are registered in England and Wales
        under company number 10550795 and have our registered office at 15 Hyde
        ParkGardens, London, United Kingdom, W2 2LU.
        <br />
        <br />
        We are a limited company.
        <br />
        <br />
        To contact us, please email tamsin@focaldata.com
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1103',
    title: 'By clicking “accept”, you accept these terms',
    body: (
      <>
        By clicking the “accept” button when presented with these terms, you
        confirm that you accept these terms of use and that you agree to comply
        with them.
        <br />
        <br />
        If you do not agree to these terms, you must not use our site.
        <br />
        We recommend that you print a copy of these terms for future reference.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1104',
    title: 'There are other terms that may apply to you',
    body: (
      <>
        These terms of use refer to the following additional terms, which also
        apply to your use of our site:
        <ul>
          <li>
            Our{' '}
            <a href="https://drive.google.com/file/d/1A4FxFAwNwNhGDr7jg3obMMP6C7g7-di1/view?usp=sharing">
              Privacy Policy
            </a>
          </li>
        </ul>
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1105',
    title: 'We may make changes to these terms',
    body: (
      <>
        We amend these terms from time to time. Every time you wish to use our
        site, please check these terms to ensure you understand the terms that
        apply at that time. In any event, we will ask you to accept any updated
        terms when we next send you a survey to participate in.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1106',
    title: 'We may make changes to our site',
    body: (
      <>
        We may update and change our site from time to time.
        <br />
        <br />
        We do not guarantee that our site, or any content on it, will always be
        available or be uninterrupted. We may suspend or withdraw, or restrict
        the availability of, all or any part of our site for business and
        operational reasons. We will try to give you reasonable notice of any
        suspension or withdrawal.
        <br />
        <br />
        You are also responsible for ensuring that all persons who access our
        site through your internet connection are aware of these terms of use
        and other applicable terms and conditions, and that they comply with
        them.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1107',
    title: 'You must keep your account details safe',
    body: (
      <>
        If you choose, or you are provided with, a user identification code,
        password or any other piece of information as part of our security
        procedures, you must treat such information as confidential. You must
        not disclose it to any third party.
        <br />
        <br />
        We have the right to disable any user identification code or password,
        whether chosen by youor allocated by us, at any time, if in our
        reasonable opinion you have failed to comply with any of the provisions
        of these terms of use.
        <br />
        <br />
        If you know or suspect that anyone other than you knows your user
        identification code or password, you must promptly notify us at
        tamsin@focaldata.com
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1108',
    title: 'How you may use material on our site',
    body: (
      <>
        We are the owner or the licensee of all intellectual property rights in
        our site, and in the material published on it. Those works are protected
        by copyright laws and treaties around the world. All such rights are
        reserved.
        <br />
        <br />
        You may print off one copy, and may download extracts, of any page(s)
        from our site for your personal use and you may draw the attention of
        others within your organisation to content posted on our site.
        <br />
        <br />
        You must not modify the paper or digital copies of any materials you
        have printed off or downloaded in any way, and you must not use any
        illustrations, photographs, video or audio sequences or any graphics
        separately from any accompanying text.
        <br />
        <br />
        Our status (and that of any identified contributors) as the authors of
        content on our site must always be acknowledged.
        <br />
        <br />
        You must not use any part of the content on our site for commercial
        purposes without obtaining a licence to do so from us or our licensors.
        <br />
        <br />
        If you print off, copy or download any part of our site in breach of
        these terms of use, your right to use our site will cease immediately
        and you must, at our option, return or destroy any copies of the
        materials you have made.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1109',
    title: 'Do not rely on information on this site',
    body: (
      <>
        The content on our site is provided for general information only. It is
        not intended to amount toadvice on which you should rely. You must
        obtain professional or specialist advice before taking, or refraining
        from, any action on the basis of the content on our site.
        <br />
        <br />
        Although we make reasonable efforts to update the information on our
        site, we make no representations, warranties or guarantees, whether
        express or implied, that the content on our site is accurate, complete
        or up to date.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1110',
    title: 'We are not responsible for websites we link to',
    body: (
      <>
        Where our site contains links to other sites and resources provided by
        third parties, these links are provided for your information only. Such
        links should not be interpreted as approval by us ofthose linked
        websites or information you may obtain from them.
        <br />
        <br />
        We have no control over the content of those sites or resources.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1111',
    title: 'User-generated content is not approved by us',
    body: (
      <>
        This website may at various times include information and materials
        uploaded by other users of the site, including to comments sections if
        these are provided. This information and these materials have not been
        verified or approved by us. The views expressed by other users on
        oursite do not represent our views or values.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1112',
    title: 'How to complain about content uploaded by other users',
    body: (
      <>
        If you wish to complain about content uploaded by other users please
        contact us using the details provided above.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1113',
    title: 'Our responsibility for loss or damage suffered by you',
    body: (
      <>
        We do not exclude or limit in any way our liability to you where it
        would be unlawful to do so. This includes liability for death or
        personal injury caused by our negligence or the negligence of our
        employees, agents or subcontractors and for fraud or fraudulent
        misrepresentation.
        <br />
        <br />
        Please note that we only provide our site to you for domestic and
        private use. You agree not to use our site for any commercial or
        business purposes, and we have no liability to you for any loss of
        profit, loss of business, business interruption, or loss of business
        opportunity.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1114',
    title: 'How we may use your personal information',
    body: (
      <>
        We will only use your personal information as set out in our{' '}
        <a href="https://drive.google.com/file/d/1A4FxFAwNwNhGDr7jg3obMMP6C7g7-di1/view?usp=sharing">
          Privacy Policy
        </a>
        .
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1115',
    title: 'Participating in surveys',
    body: (
      <>
        In return for us sending you surveys to participate in, you agree to
        provide true, accurate, current and complete information when responding
        to surveys. If you provide any information that is untrue, inaccurate,
        not current, incomplete or inconsistent with prior answers to identical
        questions, or we have reasonable grounds to suspect that such
        information is untrue, inaccurate, not current or incomplete, we reserve
        the right to choose not to send you any further surveys to participate
        in.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1116',
    title: 'Uploading content to our site',
    body: (
      <>
        Whenever you make use of a feature that allows you to upload content to
        our site, or to make contact with other users of our site, you must
        comply with the content standards set out below.
        <br />
        <br />
        You warrant that any such contribution does comply with those standards,
        and you will be liable to us and indemnify us for any breach of that
        warranty. This means you will be responsible for any loss or damage we
        suffer as a result of your breach of warranty.
        <br />
        <br />
        Any content or material you upload to our site will be considered
        non-confidential and non-proprietary and your survey responses will be
        owned by us.
        <br />
        <br />
        We also have the right to disclose your identity to any third party who
        is claiming that any content posted or uploaded by you to our site
        constitutes a violation of their intellectual property rights, or of
        their right to privacy.
        <br />
        <br />
        We have the right to remove any posting you make on our site if, in our
        opinion, your post does not comply with the content standards set out
        below.
        <br />
        <br />
        You are solely responsible for securing and backing up your content.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1117',
    title: 'We are not responsible for viruses and you must not introduce them',
    body: (
      <>
        We do not guarantee that our site will be secure or free from bugs or
        viruses.
        <br />
        <br />
        You are responsible for configuring your information technology,
        computer programmes and platform to access our site. You should use your
        own virus protection software.
        <br />
        <br />
        You must not misuse our site by knowingly introducing viruses, trojans,
        worms, logic bombs or other material that is malicious or
        technologically harmful. You must not attempt to gain unauthorised
        access to our site, the server on which our site is stored or any
        server, computer or database connected to our site. You must not attack
        our site via a denial-of-service attack ora distributed denial-of
        service attack. By breaching this provision, you would commit a criminal
        offence under the Computer Misuse Act 1990. We will report any such
        breach to the relevant law enforcement authorities and we will
        co-operate with those authorities by disclosing your identity to them.
        In the event of such a breach, your right to use our site will cease
        immediately.
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1118',
    title: 'Rules about linking to our site',
    body: (
      <>
        You may link to our home page, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it.
        <br />
        <br />
        You must not establish a link in such a way as to suggest any form of
        association, approval or endorsement on our part where none exists.
        <br />
        <br />
        You must not establish a link to our site in any website that is not
        owned by you.
        <br />
        <br />
        Our site must not be framed on any other site, nor may you create a link
        to any part of our site other than the home page.
        <br />
        <br />
        We reserve the right to withdraw linking permission without notice.
        <br />
        <br />
        The website in which you are linking must comply in all respects with
        the content standards set out in our acceptable use terms set out below.
        <br />
        <br />
        If you wish to link to or make any use of content on our site other than
        that set out above, please contact tamsin@focaldata.com
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1119',
    title: 'Acceptable Use of our site',
    body: (
      <>
        You may use our site only for lawful purposes. You may not use our site:
        <ul>
          <li>
            In any way that breaches any applicable local, national or
            international law or regulation.
          </li>
          <li>
            In any way that is unlawful or fraudulent, or has any unlawful or
            fraudulent purpose or effect.
          </li>
          <li>
            For the purpose of harming or attempting to harm minors in any way.
          </li>
          <li>To bully, insult, intimidate or humiliate any person.</li>
          <li>
            To send, knowingly receive, upload, download, use or re-use any
            material which does not comply with our content standards (as set
            out below).
          </li>
          <li>
            To transmit, or procure the sending of, any unsolicited or
            unauthorised advertising or promotional material or any other form
            of similar solicitation (spam).
          </li>
          <li>
            To knowingly transmit any data, send or upload any material that
            contains viruses, Trojan horses, worms, time-bombs, keystroke
            loggers, spyware, adware or any other harmful programs or similar
            computer code designed to adversely affect the operation of any
            computer software or hardware.
          </li>
        </ul>
        <br />
        You also agree:
        <ul>
          <li>
            Not to reproduce, duplicate, copy or re-sell any part of our site in
            contravention of the provisions of our terms of website use.
          </li>
          <li>
            Not to access without authority, interfere with, damage or disrupt:
            <ul>
              <li>any part of our site;</li>
              <li>any equipment or network on which our site is stored;</li>
              <li>any software used in the provision of our site; or</li>
              <li>
                any equipment or network or software owned or used by any third
                party.
              </li>
            </ul>
          </li>
        </ul>
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1120',
    title: 'Content standards',
    body: (
      <>
        These content standards apply to any and all material which you
        contribute to our site (<span>Contribution</span>), and to any
        interactive services associated with it.
        <br />
        <br />
        The Content Standards must be complied with in spirit as well as to the
        letter. The standards apply to each part of any Contribution as well as
        to its whole.
        <br />
        <br />
        We will determine, in our discretion, whether a Contribution breaches
        the Content Standards.
        <br />A Contribution must:
        <ul>
          <li>Be accurate (where it states facts).</li>
          <li>Be genuinely held (where it states opinions).</li>
          <li>
            Comply with the law applicable in England and Wales and in any
            country from which it is posted.
          </li>
          <br />A Contribution must not:
          <li>Be defamatory of any person.</li>
          <li>Be obscene, offensive, hateful or inflammatory.</li>
          <li>Bully, insult, intimidate or humiliate.</li>
          <li>Promote sexually explicit material.</li>
          <li>Promote violence.</li>
          <li>
            Promote discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age.
          </li>
          <li>
            Infringe any copyright, database right or trade mark of any other
            person.
          </li>
          <li>Be likely to deceive any person.</li>
          <li>
            Breach any legal duty owed to a third party, such as a contractual
            duty or a duty of confidence.
          </li>
          <li>Promote any illegal activity.</li>
          <li>Be in contempt of court.</li>
          <li>
            Be threatening, abuse or invade another&apos;s privacy, or cause
            annoyance, inconvenience or needless anxiety.
          </li>
          <li>
            Be likely to harass, upset, embarrass, alarm or annoy any other
            person.
          </li>
          <li>
            Impersonate any person, or misrepresent your identity or affiliation
            with any person.
          </li>
          <li>
            Give the impression that the Contribution emanates from us, if this
            is not the case.
          </li>
          <li>
            Advocate, promote, incite any party to commit, or assist any
            unlawful or criminal act such as (by way of example only) copyright
            infringement or computer misuse.
          </li>
          <li>
            Contain a statement which you know or believe, or have reasonable
            grounds for believing, that members of the public to whom the
            statement is, or is to be, published are likely to understand as a
            direct or indirect encouragement or other inducement to the
            commission, preparation or instigation of acts of terrorism.
          </li>
          <li>
            Contain any advertising or promote any services or web links to
            other sites.
          </li>
        </ul>
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1121',
    title: 'Breach of these terms',
    body: (
      <>
        When we consider that a breach of these terms has occurred, we may take
        such action as we deem appropriate.
        <br />
        <br />
        Failure to comply with these terms may result in our taking all or any
        of the following actions:
        <ul>
          <li>
            Immediate, temporary or permanent withdrawal of your right to use
            our site.
          </li>
          <li>
            Immediate, temporary or permanent removal of any Contribution
            uploaded by you to our site.
          </li>
          <li>Issue of a warning to you.</li>
          <li>
            Legal proceedings against you for reimbursement of all costs on an
            indemnity basis (including, but not limited to, reasonable
            administrative and legal costs) resulting from the breach.
          </li>
          <li>Further legal action against you.</li>
          <li>
            Disclosure of such information to law enforcement authorities as we
            reasonably feel is necessary or as required by law.
          </li>
        </ul>
      </>
    )
  },
  {
    id: '4fcb18f7-5a17-44d1-89cf-df4a9b9f1122',
    title: "What's in these terms?",
    body: (
      <>
        If you are a consumer, please note that these terms of use, their
        subject matter and their formation, are governed by English law. You and
        we both agree that the courts of England and Wales will have exclusive
        jurisdiction except that if you are a resident of Northern Ireland you
        may also bring proceedings in Northern Ireland, and if you are resident
        of Scotland, you may also bring proceedings in Scotland.
        <br />
        <br />
        If you are a business, these terms of use, their subject matter and
        their formation (and any non-contractual disputes or claims) are
        governed by English law. We both agree to the exclusive jurisdiction of
        the courts of England and Wales.
      </>
    )
  }
]
