import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { MaskingRuleClause, MatrixItem, MatrixRow } from 'model/questionnaire'
import getPersistedRespondentChoices from './getPersistedRespondentChoices'
import { setHiddenByDisplayLogic } from './hiddenByDisplayLogic'
import { maskingRuleMetByAtLeastOneMaskingKey, getClause } from './maskingLogic'

const notMaskedMatrixRows =
  (persistedRespondentChoices: PersistentRespondentChoice[]) =>
  (matrixRow: MatrixRow): boolean => {
    const isMaskingRuleMet = maskingRuleMetByAtLeastOneMaskingKey(
      persistedRespondentChoices
    )

    if (matrixRow.maskingRules?.length > 0)
      return getClause(
        matrixRow.maskingRules?.filter((_, index) => index === 1)
      ) === MaskingRuleClause.AND
        ? !matrixRow.maskingRules.some(
            (maskingRule) => !isMaskingRuleMet(maskingRule)
          )
        : matrixRow.maskingRules.some(isMaskingRuleMet)
    return true
  }

const getMaskedMatrixRows = (
  matrixRows: MatrixRow[] | undefined
): string[] | undefined => {
  const persistedRespondentChoices = getPersistedRespondentChoices()
  return matrixRows
    ?.filter((row) => !notMaskedMatrixRows(persistedRespondentChoices)(row))
    .map((row) => row.question.questionId)
}

export const hasMaskingRules = (row?: MatrixRow[]): boolean => {
  return row?.some((row) => row.maskingRules.length > 0) ?? false
}

const getEntryItemWithFilteredMaskedMatrixRows = (
  entryItem: MatrixItem
): MatrixItem => {
  if (hasMaskingRules(entryItem.matrixRows)) {
    const maskedMatrixRows = getMaskedMatrixRows(entryItem.matrixRows)
    maskedMatrixRows?.forEach((maskedMatrixRowId) =>
      setHiddenByDisplayLogic(maskedMatrixRowId)
    )

    return {
      ...entryItem,
      matrixRows: entryItem.matrixRows?.filter(
        (row) => !maskedMatrixRows?.includes(row.question.questionId)
      )
    }
  }
  return entryItem
}

const entryHasNoMatrixRows = (matrixRows: MatrixRow[] | undefined) => {
  return matrixRows?.length === 0
}

export { getEntryItemWithFilteredMaskedMatrixRows, entryHasNoMatrixRows }
