import { AxiosError } from 'axios'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { RespondentProgress } from 'model/respondentProgress'
import { RespondentEvent, RespondentStatus } from 'model/respondentStatus'
import { useContext } from 'react'
import { RESPONDENT_STATUS_API_URL } from 'utils/constants'
import { getNowTimeString, getUtcOffset } from 'utils/datetime'
import { useMandatoryQueryParams } from './useMandatoryQueryParams'
import usePostCallback from './usePostCallback'

interface Data {
  error: AxiosError | undefined
  sendRespondentStatus: () => void
}

const endpoint = `${process.env.REACT_APP_DOMAIN}${RESPONDENT_STATUS_API_URL}`

const getRespondentStatus: (progress: RespondentProgress) => RespondentEvent = (
  progress
) => {
  if (progress.isCompleted) return RespondentEvent.Complete
  if (progress.isQualityTerminated) return RespondentEvent.QualityTerminate
  if (progress.isQuotaFull) return RespondentEvent.QuotaFull
  if (progress.isScreened) return RespondentEvent.ScreenOut

  return RespondentEvent.NotFound
}

const useSendRespondentStatus: () => Data = () => {
  const mandatoryQueryParams = useMandatoryQueryParams()

  const {
    respondentProgress: [progress]
  } = useContext<AppState>(AppStateContext)
  const [error, sendRespondentStatusInternal] =
    usePostCallback<RespondentStatus>()

  const sendRespondentStatus: () => void = () => {
    if (!mandatoryQueryParams || !progress) {
      // TODO: log to sentry? should never get here
      return
    }

    const payload: RespondentStatus = {
      respondentId: mandatoryQueryParams.respondentId,
      status: getRespondentStatus(progress),
      surveyId: mandatoryQueryParams.surveyId,
      panelSupplierCode: mandatoryQueryParams.panelSupplier,
      isPreview: mandatoryQueryParams.preview,
      metaData: null,
      submissionDatetime: getNowTimeString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utcOffset: getUtcOffset()
    }

    sendRespondentStatusInternal(payload, endpoint)
  }

  return { error, sendRespondentStatus }
}

export default useSendRespondentStatus
