import ReactGA from 'react-ga'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const isProd = process.env.NODE_ENV === 'production'

export const useGA = () => {
  const location = useLocation()
  useEffect(() => {
    if (isProd) {
      ReactGA.initialize('UA-107671203-5')
    }
  }, [])

  useEffect(() => {
    if (isProd) {
      ReactGA.pageview(location.pathname)
    }
  }, [location.pathname])
}
