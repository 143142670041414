import {
  Grid,
  Spinner,
  SpinnerColor,
  SpinnerSize
} from '@focaldata/cin-ui-components'
import React from 'react'

const LoadingOverlay: React.FC = () => {
  return (
    <Grid
      container
      style={{ height: '100vh' }}
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size={SpinnerSize.l} color={SpinnerColor.Primary} />
    </Grid>
  )
}

export default LoadingOverlay
