import axios, { AxiosError } from 'axios'
import { useCallback, useState } from 'react'
import useCollectDataParameter from './useCollectDataParameter'

export type Callback<T> = (payload: T, url: string) => void

const usePostCallback: <T>() => [AxiosError | undefined, Callback<T>] = <
  T
>() => {
  // only send if it's not collect = true
  const collectData = useCollectDataParameter()
  const [error, setError] = useState<AxiosError | undefined>()
  const sendRespondentStatus = useCallback<Callback<T>>(
    async (payload, url) => {
      try {
        await axios.post(url, payload)
      } catch (err) {
        setError(error)
      }
    },
    [error]
  )

  const sendRespondentStatusDud = useCallback(() => Promise.resolve(), [])

  return [error, collectData ? sendRespondentStatus : sendRespondentStatusDud]
}

export default usePostCallback
