import { AppState, AppStateContext } from 'containers/State/AppState'
import ErrorOverlay from 'controls/ErrorOverlay/ErrorOverlay'
import LoadingOverlay from 'controls/LoadingOverlay/LoadingOverlay'
import React, { PropsWithChildren, useContext } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { noContextMethod } from 'utils/context'

export interface PfmData {
  next: () => void
}

export const PfmContext = React.createContext<PfmData>({
  next: noContextMethod
})

const PageFlowManager = (props: PropsWithChildren<{}>) => {
  const { children }: PropsWithChildren<{}> = props
  const location = useLocation()
  const navigate = useNavigate()
  const {
    respondentProgress: [progress],
    renderedQuestionnaire: [data, loading, error]
  } = useContext<AppState>(AppStateContext)

  const pushHistory: (pathName: string) => void = (pathName) => {
    navigate({
      pathname: pathName,
      search: location.search
    })
  }

  const isSurveyCompleted = progress
    ? progress.isCompleted ||
      progress.isQualityTerminated ||
      progress.isQuotaFull ||
      progress.isScreened
    : false

  const getPageRedirect: () => JSX.Element | undefined = () => {
    if (
      progress &&
      !progress.isTermsAgreed &&
      location.pathname !== '/terms' &&
      location.pathname !== '/info'
    ) {
      return <Navigate replace to={`/info${location.search}`} />
    }

    if (
      progress &&
      progress.isTermsAgreed &&
      !isSurveyCompleted &&
      location.pathname !== '/questionnaire'
    ) {
      return <Navigate replace to={`/questionnaire${location.search}`} />
    }

    if (progress && isSurveyCompleted && location.pathname !== '/complete') {
      return <Navigate replace to={`/complete${location.search}`} />
    }

    return undefined
  }

  const handleNext: () => void = () => {
    if (location.pathname === '/info' && data) {
      pushHistory('/terms')
    } else if (location.pathname === '/terms' && progress?.isTermsAgreed) {
      pushHistory('/questionnaire')
    } else if (location.pathname === '/questionnaire') {
      pushHistory('/complete')
    }
  }

  const redirectTo = getPageRedirect()
  if (redirectTo) {
    return redirectTo
  }

  if (error) {
    return <ErrorOverlay />
  }

  if (loading) {
    return <LoadingOverlay />
  }

  return (
    <PfmContext.Provider value={{ next: handleNext }}>
      {children}
    </PfmContext.Provider>
  )
}

export default PageFlowManager
