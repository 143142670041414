import { FreeText, ResponseText } from '@focaldata/cin-ui-components'
import {
  CfmData,
  CfmContext
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import {
  QuestionItem,
  QuestionnaireEntry,
  SettingCodes,
  SettingValues
} from 'model/questionnaire'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import { ResponseOptionChoice } from 'model/responseChoice'
import { getNowTimeString } from 'utils/datetime'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { getSetting } from 'utils/question'

interface Props {
  entry: QuestionnaireEntry
}

const isCompactMap = new Map<SettingValues, boolean>([
  [SettingValues.Numeric1, true],
  [SettingValues.Numeric2, true],
  [SettingValues.Numeric3, true],
  [SettingValues.PostCode, true],
  [SettingValues.Email, true],
  [SettingValues.Unstructured, false]
])

const isNumericMap = new Map<SettingValues, boolean>([
  [SettingValues.Numeric1, true],
  [SettingValues.Numeric2, true],
  [SettingValues.Numeric3, true],
  [SettingValues.PostCode, false],
  [SettingValues.Email, false],
  [SettingValues.Unstructured, false]
])

const postCodeInstructionsByLocale = new Map<string, string>([
  [
    'en-GB',
    'Please enter your postcode without the last two letters, e.g. N1 6AH -> N1 6, EH8 8AJ -> EH8 8, WC2B 4AB -> WC2B 4'
  ],
  ['en-GB_full', 'Please enter your postcode, e.g. N1 6AH, EH8 8AJ, WC2B 4AB'],
  ['en-US', 'Please enter your postcode in this format: 99999 or 99999-9999']
])

const postCodeRegexByLocale = new Map<string, RegExp>([
  ['en-GB', /^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s\d{1}$/],
  ['en-GB_full', /^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s\d{1}[a-zA-Z]{2}$/],
  ['en-US', /^\d{5}(-\d{4})?$/]
])

const postCodeHelperTextsByLocale = new Map<string, string>([
  [
    'en-GB',
    'Sorry, please enter the postcode with the last two letters missing (e.g. SW1 5, EH 8)'
  ],
  ['en-GB_full', 'Sorry, please enter the postcode (e.g. SW1A 2AA, EH8 8AJ)'],
  ['en-US', 'Sorry, please enter a valid US postcode']
])

const FreeTextEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem
  const progress = useEntryProgress(entry)
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const { sendResponseOptionChoices } = useSendResponseChoice()
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const { formatMessage, locale } = useIntl()

  const DEFAULT_LOCALE = 'en-GB'

  const type =
    getSetting(entryItem.settingValues, SettingCodes.ContentStructure) ||
    SettingValues.Unstructured
  const isPostCodeType =
    type === SettingValues.PostCode || type === SettingValues.PostCodeFull
  const typeKey = isPostCodeType ? SettingValues.PostCode : type
  const freeTextControlSettings = {
    isCompact: isCompactMap.get(typeKey),
    numericOnly: isNumericMap.get(typeKey)
  }
  const isFullPostalCodeType = type === SettingValues.PostCodeFull
  const shouldValidatePostCode =
    isPostCodeType && (locale === DEFAULT_LOCALE || locale === 'en-US')
  const localeKey =
    isFullPostalCodeType && locale === DEFAULT_LOCALE
      ? `${locale}_full`
      : locale

  const postCodeInstructionByLocale =
    postCodeInstructionsByLocale.get(localeKey) ||
    postCodeInstructionsByLocale.get(DEFAULT_LOCALE) ||
    ''

  // This is for now only activated for the UK so there's no need to translate these
  // const validationString: string = type.toString()
  // const reg = new RegExp(validationString.replace('postcode_uk__', ''))
  const reg =
    postCodeRegexByLocale.get(localeKey) ||
    /^[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s\d{1}$/
  const validation = {
    matchPattern: reg,
    helperText:
      postCodeHelperTextsByLocale.get(localeKey) ||
      postCodeHelperTextsByLocale.get(DEFAULT_LOCALE) ||
      ''
  }
  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: shouldValidatePostCode ? (
      postCodeInstructionByLocale
    ) : (
      <FormattedMessage
        id="question_freeTextInstructions"
        defaultMessage="Enter your answer in the box below"
      />
    ),
    typeYourAnswerHere: formatMessage({
      id: 'question_freeTextPlaceholder',
      defaultMessage: 'Type your answer here...'
    }),
    characterLimit: formatMessage({
      id: 'question_freeTextCharactersRemaining',
      defaultMessage: 'characters remaining'
    })
  }

  const getResponseOptionChoices: (
    responseText: ResponseText
  ) => ResponseOptionChoice = (responseText) => {
    return {
      value: responseText.text,
      responseDatetime: responseText.timestamp || getNowTimeString()
    }
  }

  const handleNext: (selectedText: ResponseText) => void = (selectedText) => {
    if (!preview) {
      sendResponseOptionChoices(
        entryItem.question.questionId,
        entry,
        entry.entryType,
        renderingDateTime,
        [getResponseOptionChoices(selectedText)]
      )
    }
    getNextEntryPosition()
  }
  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    getNextEntryPosition()
  }

  return (
    <FreeText
      item={entryItem}
      settings={freeTextControlSettings}
      validation={shouldValidatePostCode ? validation : undefined}
      loc={loc}
      preview={preview}
      progress={progress}
      next={{ disabled: false, loading: false, onNext: handleNext }}
    />
  )
}

export default FreeTextEntry
