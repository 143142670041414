import React from 'react'
import {
  Grid,
  Text,
  TextAlign,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import useStyles from './InfoPageLayout.styles'

interface Props {
  title?: JSX.Element | string
  subtitle?: JSX.Element | string
  visual?: JSX.Element | string
  action?: JSX.Element | string
}

const InfoPageLayout: React.FC<Props> = (props: Props) => {
  const { title, subtitle, visual, action }: Props = props
  const { classes } = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justifyContent="center"
      className={classes.container}
    >
      <div className={classes.visual}>{visual}</div>
      <Text
        size={TextSize.lxl}
        weight={TextWeight.SemiBold}
        className={classes.title}
        align={TextAlign.Center}
      >
        {title}
      </Text>
      <Text highlight={TextHighlight.Background} className={classes.subtitle}>
        {subtitle}
      </Text>
      {action}
    </Grid>
  )
}

export default InfoPageLayout
