import { AppState, AppStateContext } from 'containers/State/AppState'
import { QuestionnaireEntry } from 'model/questionnaire'
import { useContext } from 'react'

interface Progress {
  current: number
  total: number
}

const useEntryProgress: (entry: QuestionnaireEntry) => Progress = (entry) => {
  const {
    renderedQuestionnaire: [data]
  } = useContext<AppState>(AppStateContext)

  // TODO: maybe if data is empty or something, log to Sentry?

  const progress = {
    current: entry.renderedEntryPosition,
    total: data?.questionnaire.entries.length || 0
  }

  return progress
}

export default useEntryProgress
