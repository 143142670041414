import React from 'react'
import CompletePage from 'containers/CompletePage/CompletePage'
import InfoPage from 'containers/InfoPage/InfoPage'
import QuestionnairePage from 'containers/QuestionnairePage/QuestionnairePage'
import PageFlowManager from 'containers/PageFlowManager/PageFlowManager'
import TermsPage from 'containers/TermsPage/TermsPage'
import { Route, Routes } from 'react-router-dom'
import AppStateProvider from 'containers/State/AppState'
import IntlProvider from 'intl/IntlProvider'
import QuestionFlowManager from 'containers/QuestionFlowManager/QuestionFlowManager'
import { useGA } from 'hooks/useGA'

const App = () => {
  useGA()

  return (
    <AppStateProvider>
      <IntlProvider>
        <PageFlowManager>
          <Routes>
            <Route path="/info" element={<InfoPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route
              path="/questionnaire"
              element={
                <QuestionFlowManager>
                  <QuestionnairePage />
                </QuestionFlowManager>
              }
            />
            <Route path="/complete" element={<CompletePage />} />
          </Routes>
        </PageFlowManager>
      </IntlProvider>
    </AppStateProvider>
  )
}

export default App
