export enum DeploymentEnvironment {
  Dev,
  Prod
}

export const getDeploymentEnvironment = (): DeploymentEnvironment => {
  const regex = window.location.hostname.match(/\.[^\s.]+$/)
  // If we don't have details we assume it's prod, just to be sure
  let env = DeploymentEnvironment.Prod
  if (regex && regex.length > 0) {
    const extension = regex[0]
    if (extension === '.dev') {
      env = DeploymentEnvironment.Dev
    }
  }
  return env
}
