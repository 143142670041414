import { AxiosError } from 'axios'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { RespondentInformation } from 'model/respondentInformation'
import { useContext } from 'react'
import { RESPONDENT_INFO_API_URL } from 'utils/constants'
import { getNowTimeString, getUtcOffset } from 'utils/datetime'
import { useCintQueryParams } from './useCintQueryParams'
import { useMandatoryQueryParams } from './useMandatoryQueryParams'
import usePostCallback from './usePostCallback'

interface Data {
  error: AxiosError | undefined
  sendRespondentInfo: () => void
}

const endpoint = `${process.env.REACT_APP_DOMAIN}${RESPONDENT_INFO_API_URL}`

const useSendRespondentInfo: () => Data = () => {
  const mandatoryQueryParams = useMandatoryQueryParams()
  const cintQueryParams = useCintQueryParams()
  const {
    renderedQuestionnaire: [questionnaireData]
  } = useContext<AppState>(AppStateContext)
  const [error, sendRespondentInfoInternal] =
    usePostCallback<RespondentInformation>()

  const sendRespondentInfo: () => void = () => {
    if (!mandatoryQueryParams) {
      // TODO: log to sentry? should never get here
      return
    }

    const payload: RespondentInformation = {
      ...cintQueryParams,
      respondentId: mandatoryQueryParams.respondentId,
      surveyId: mandatoryQueryParams.surveyId,
      panelSupplierCode: mandatoryQueryParams.panelSupplier,
      isPreview: mandatoryQueryParams.preview,
      metaData: null,
      submissionDatetime: getNowTimeString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utcOffset: getUtcOffset(),
      locale: questionnaireData?.questionnaire.locale
    }

    sendRespondentInfoInternal(payload, endpoint)
  }

  return { error, sendRespondentInfo }
}

export default useSendRespondentInfo
