import React from 'react'
import Entry from 'controls/entries/Entry'
import ErrorOverlay from 'controls/ErrorOverlay/ErrorOverlay'
import useQuestionPiping from 'hooks/useQuestionPiping'

const QuestionnairePage: React.FC = () => {
  const currentEntry = useQuestionPiping()

  if (!currentEntry) {
    // TODO: log to sentry, this should never happen
    return <ErrorOverlay />
  }

  window.scroll({
    top: 0,
    behavior: 'smooth'
  })

  return <Entry entry={currentEntry} />
}

export default QuestionnairePage
