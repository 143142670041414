import React, { PropsWithChildren, useContext } from 'react'
import { IntlProvider as Intl } from 'react-intl'
import copy from 'intl/copy'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { ThemeProvider } from '@focaldata/cin-ui-components'
import { getDirection } from './IntlUtils'

type Locales =
  | 'en-AU'
  | 'en-CA'
  | 'en-GB'
  | 'en-IE'
  | 'en-IN'
  | 'en-US'
  | 'en-ZA'
  | 'es-AR'
  | 'es-ES'
  | 'fr-FR'
  | 'hr-HR'
  | 'it-IT'
  | 'nl-NL'
  | 'pt-BR'
  | 'tr-TR'
  | 'de-DE'
  | 'es-MX'
  | 'da-DK'
  | 'el-GR'
  | 'hi-IN'
  | 'hu-HU'
  | 'ja-JP'
  | 'ko-KR'
  | 'zh-CN'
  | 'nb-NO'
  | 'pl-PL'
  | 'ro-RO'
  | 'ru-RU'
  | 'th-TH'
  | 'fr-BE'
  | 'en-SG'
  | 'en-HK'
  | 'cs-CZ'
  | 'ar-AE'
  | 'ar-SA'
  | 'en-IL'
  | 'vi-VN'
  | 'zh-TW'
  | 'sv-SE'

const IntlProvider = (props: PropsWithChildren<{}>) => {
  const { children }: PropsWithChildren<{}> = props
  const {
    renderedQuestionnaire: [questionnaire]
  } = useContext<AppState>(AppStateContext)
  const locale: Locales = questionnaire
    ? (`${questionnaire.questionnaire.locale.language}-${questionnaire.questionnaire.locale.country}` as Locales)
    : 'en-GB'
  const direction = getDirection(questionnaire?.questionnaire.locale.language)
  return (
    <ThemeProvider direction={direction}>
      <Intl locale={locale} messages={copy[locale]}>
        {children}
      </Intl>
    </ThemeProvider>
  )
}

export default IntlProvider
