import { RenderedQuestionnaire } from 'model/questionnaire'
import { RespondentProgress } from 'model/respondentProgress'
import { getEntryId } from './removeRespondentChoiceFromLocalstorage'

const DISPLAY_LOGIC_STATE_KEY = 'displayLogicState'

export const setHiddenByDisplayLogic: (questionId: string) => void = (
  questionId
) => {
  const questionsHiddenByDisplayLogicString =
    window.localStorage.getItem(DISPLAY_LOGIC_STATE_KEY) || ''

  const questionsHiddenByDisplayLogic: string[] =
    questionsHiddenByDisplayLogicString
      ? JSON.parse(questionsHiddenByDisplayLogicString)
      : []

  if (!questionsHiddenByDisplayLogic.includes(questionId))
    questionsHiddenByDisplayLogic.push(questionId)

  window.localStorage.setItem(
    DISPLAY_LOGIC_STATE_KEY,
    JSON.stringify(questionsHiddenByDisplayLogic)
  )
}

export const removeHiddenByDisplayLogic: (
  data: RenderedQuestionnaire | undefined,
  respondentProgress: RespondentProgress | undefined
) => void = (data, respondentProgress) => {
  if (respondentProgress) {
    const questionsHiddenByDisplayLogicString =
      window.localStorage.getItem(DISPLAY_LOGIC_STATE_KEY) || ''

    const questionsHiddenByDisplayLogic: string[] =
      questionsHiddenByDisplayLogicString
        ? JSON.parse(questionsHiddenByDisplayLogicString)
        : []

    const { currentEntryPosition } = respondentProgress

    const entry = data?.questionnaire?.entries.find(
      (entry) => entry.entryPosition === currentEntryPosition - 1
    )

    const entryId = getEntryId(entry)

    const newListOfHiddenQuestions = questionsHiddenByDisplayLogic.filter(
      (id) => id !== entryId
    )

    window.localStorage.setItem(
      DISPLAY_LOGIC_STATE_KEY,
      JSON.stringify(newListOfHiddenQuestions)
    )
  }
}

export const isQuestionHiddenByDisplayLogic: (questionId: string) => boolean = (
  questionId
) => {
  const questionsHiddenByDisplayLogicString =
    window.localStorage.getItem(DISPLAY_LOGIC_STATE_KEY) || ''

  const questionsHiddenByDisplayLogic: string[] =
    questionsHiddenByDisplayLogicString
      ? JSON.parse(questionsHiddenByDisplayLogicString)
      : []

  const found = questionsHiddenByDisplayLogic.find((id) => id === questionId)

  return !!found
}

export const clearQuestionsHiddenByDisplayLogic: () => void = () => {
  window.localStorage.removeItem(DISPLAY_LOGIC_STATE_KEY)
}
