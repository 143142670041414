import { useMandatoryQueryParams } from 'hooks/useMandatoryQueryParams'
import useRenderedQuestionnaire from 'hooks/useRenderedQuestionnaire'
import useRespondentProgress from 'hooks/useRespondentProgress'
import { RenderedQuestionnaire } from 'model/questionnaire'
import { RespondentProgress } from 'model/respondentProgress'
import React, { PropsWithChildren, useCallback, useEffect } from 'react'
import { noContextMethod } from 'utils/context'
import { clearQuestionsHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'
import { clearResponseChoices } from 'utils/removeRespondentChoiceFromLocalstorage'

export interface AppState {
  respondentProgress: [
    value: RespondentProgress | undefined,
    set: (newVal: RespondentProgress) => void
  ]
  renderedQuestionnaire: [
    data: RenderedQuestionnaire | undefined,
    loading: boolean,
    error: boolean
  ]
}

export const AppStateContext = React.createContext<AppState>({
  respondentProgress: [undefined, noContextMethod],
  renderedQuestionnaire: [undefined, false, false]
})

const AppStateProvider = (props: PropsWithChildren<{}>) => {
  const { children }: PropsWithChildren<{}> = props
  const { renderedQuestionnaire, loading, error } = useRenderedQuestionnaire()
  const { respondentProgress, setRespondentProgress } = useRespondentProgress()
  const mandatoryParams = useMandatoryQueryParams()

  const getStartRespondentProgress: (
    termsAgreed: boolean
  ) => RespondentProgress | undefined = useCallback(
    (termsAgreed) => {
      if (!mandatoryParams) {
        // TODO: log to sentry
        return undefined
      }

      const { surveyId, respondentId } = mandatoryParams

      return {
        currentEntryPosition: 0,
        previousEntryPosition: -1,
        isCompleted: false,
        isQuotaFull: false,
        isScreened: false,
        isQualityTerminated: false,
        isTermsAgreed: termsAgreed,
        questionnaireLen:
          renderedQuestionnaire?.questionnaire.entries?.length || 0,
        respondentId,
        surveyId
      }
    },
    [mandatoryParams, renderedQuestionnaire]
  )

  useEffect(() => {
    const progress = getStartRespondentProgress(true)
    if (mandatoryParams?.preview && progress) {
      setRespondentProgress(progress)
      clearQuestionsHiddenByDisplayLogic()
      clearResponseChoices()
    }
  }, [
    renderedQuestionnaire,
    getStartRespondentProgress,
    mandatoryParams,
    setRespondentProgress
  ])

  if (
    renderedQuestionnaire &&
    (!respondentProgress ||
      respondentProgress?.surveyId !== renderedQuestionnaire?.surveyId) &&
    mandatoryParams
  ) {
    const progress = getStartRespondentProgress(false)
    if (progress) {
      setRespondentProgress(progress)
    }
  }

  const contextValue: AppState = {
    respondentProgress: [respondentProgress, setRespondentProgress],
    renderedQuestionnaire: [renderedQuestionnaire, loading, error]
  }

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  )
}

export default AppStateProvider
