import { EntryType } from 'model/common'
import {
  LogicClauseProposition,
  QuestionResponseOption,
  MatrixResponseOption,
  LogicPropositionType
} from 'model/questionLogic'
import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { isQuestionHiddenByDisplayLogic } from './hiddenByDisplayLogic'
import getPersistedRespondentChoices from './getPersistedRespondentChoices'

const checkIsPropositionInLocalstorage = (
  persistenRespondentChoices: PersistentRespondentChoice[],
  proposition: LogicClauseProposition
) => {
  if (
    proposition.propositionType ===
    LogicPropositionType.QuestionResponseOptionType
  ) {
    const questionProposition =
      proposition.proposition as QuestionResponseOption

    // Checking if the question to which this current entity depends on has been hidden by display logic
    // if it has, skip this one too
    // if not, do the rest of the checks
    const hasPreviousBeenHiddenByDisplayLogic = isQuestionHiddenByDisplayLogic(
      questionProposition.questionId
    )

    if (hasPreviousBeenHiddenByDisplayLogic) {
      return false
    }

    // Checking if the question to which this current entity depends on has been saved in localstorage
    // if yes, continue
    // if not, it means that the question to which this entity depends on has been skipped (skip logic)
    // and thus the current entry should be displayed
    const isDependOnQuestionIdInLocalstorage = persistenRespondentChoices.some(
      (persistenRespondentChoice) =>
        persistenRespondentChoice.questionId === questionProposition?.questionId
    )

    if (!isDependOnQuestionIdInLocalstorage) {
      return true
    }

    const isQuestionPropositionInLocalstorage = persistenRespondentChoices.some(
      (persistenRespondentChoice) =>
        persistenRespondentChoice.questionId ===
          questionProposition?.questionId &&
        persistenRespondentChoice.responseChoiceIds.includes(
          questionProposition.responseOptionId
        )
    )
    return isQuestionPropositionInLocalstorage
  }

  if (
    proposition.propositionType ===
    LogicPropositionType.MatrixResponseOptionType
  ) {
    const matrixProposition = proposition.proposition as MatrixResponseOption

    // Checking if the question to which this current entity depends on has been hidden by display logic
    // if it has, skip this one too
    // if not, do the rest of the checks
    const hasPreviousBeenHiddenByDisplayLogic = isQuestionHiddenByDisplayLogic(
      matrixProposition.matrixTitleId
    )

    if (hasPreviousBeenHiddenByDisplayLogic) {
      return false
    }

    const isMatrixPropositionInLocalstorage = persistenRespondentChoices.some(
      (persistenRespondentChoice) =>
        persistenRespondentChoice.questionId ===
          matrixProposition?.questionId &&
        persistenRespondentChoice.responseChoiceIds.includes(
          matrixProposition.responseOptionId
        ) &&
        persistenRespondentChoice.matrixTitleId ===
          matrixProposition.matrixTitleId
    )
    return isMatrixPropositionInLocalstorage
  }
  return false
}

const hasValidProposition = (
  propositions: LogicClauseProposition[],
  persistenRespondentChoices: PersistentRespondentChoice[]
): boolean => {
  // Since this is a "OR", one valid proposition is enough
  const arePropositionsValid = propositions.some((proposition) => {
    const isPropositionInLocalstorage = checkIsPropositionInLocalstorage(
      persistenRespondentChoices,
      proposition
    )
    return isPropositionInLocalstorage
  })

  return arePropositionsValid
}

const showEntityBasedOnLogic = (
  clauses: LogicClauseProposition[][],
  persistenRespondentChoices: PersistentRespondentChoice[]
) => {
  // All clauses must have valid proposition to display the entity since it is an "AND"
  return clauses.every((clause) => {
    return hasValidProposition(clause, persistenRespondentChoices)
  })
}

const displayEntityBasedOnLogic = (
  entityLogic: LogicClauseProposition[][] | undefined,
  entryType: EntryType
) => {
  const persistenRespondentChoices = getPersistedRespondentChoices()

  if (
    entityLogic &&
    entityLogic.length > 0 &&
    persistenRespondentChoices &&
    persistenRespondentChoices.length > 0
  ) {
    if (
      entryType === EntryType.QuestionEntryType ||
      entryType === EntryType.MatrixEntryType ||
      entryType === EntryType.TextCardEntryType
    ) {
      const shouldDisplayEntity = showEntityBasedOnLogic(
        entityLogic,
        persistenRespondentChoices
      )
      return shouldDisplayEntity
    }

    return true // For now, always display if it is not a QuestionEntryType or a MatrixEntryType or TextCardEntryType
  }

  return true // Always display if there is no logic
}

export default displayEntityBasedOnLogic
